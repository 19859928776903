import React, { useState } from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { Colors, FontStyles } from '@flow/style'
import { Icons } from '@flow/icons'
import { TransparentButton } from '@flow/buttons'
import { formatDateTime } from '../utils/dateFormatter'
import task from '../services/task'
import { QueueItemProps } from '../types/queueItem'

const {
  featureToggles = [],
  labels = ['red', 'blue', 'black', 'pink', 'yellow', 'purple', 'orange']
} = window.stacc_env
const isSwedenPortal = featureToggles.includes('se-portal')
const borderSize = '3px'

const ItemNavLink = styled(NavLink)`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  border-bottom: 1px solid ${Colors.Grey3};
  ${FontStyles.Small};

  &.active {
    pointer-events: none;
  }
`

interface ContainerProps {
  pendingAction: boolean
  pendingChat: boolean
}

const Container = styled.div<ContainerProps>`
  padding: 10px;
  border-left: ${borderSize} solid transparent;
  background-color: ${(props) =>
    props.pendingAction || props.pendingChat
      ? Colors.OrangeLightest
      : Colors.Grey4};

  a.active > & {
    background-color: ${Colors.SeaLighter};
    border-left: ${borderSize} solid ${Colors.SeaDark};
  }
`

const Heading = styled.div`
  display: flex;
  justify-content: space-between;
`

const Body = styled.div`
  display: flex;
  justify-content: space-between;
`

const Title = styled.p`
  ${FontStyles.LargeStrong};
`

const Notifications = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const markColorToFlowColor = (color: string) => {
  switch (color) {
    case 'red':
      return ['#dc626d', '#cd313d']
    case 'green':
      return ['#52CE90', '#3EC481']
    default:
      return [color, color]
  }
}

interface MarkProps {
  color: string
}

const Mark = styled.div<MarkProps>`
  box-sizing: content-box;
  height: 1em;
  width: 1em;
  background-color: ${(props) => markColorToFlowColor(props.color)[0]};
  border: 0.2em solid ${(props) => markColorToFlowColor(props.color)[1]};
  border-radius: 0.15em;
`

const DropdownButton = styled(TransparentButton)`
  pointer-events: initial;
  padding: 0;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  &:active {
    background-color: rgba(0, 0, 0, 0.1);
  }

  display: none;
  .active & {
    display: block;
  }
`

const DropdownContainer = styled.div`
  pointer-events: initial;
  position: absolute;
  z-index: 9999;
  top: 100%;
  right: 0;
  background-color: white;
  border: 1px solid lightgrey;
  width: 7.2em;
  flex-direction: column;

  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
`

interface DropdownItemProps {
  isSelected: boolean
}

const DropdownItem = styled.div<DropdownItemProps>`
  display: flex;
  align-self: flex-start;
  width: 90%;
  padding: 0.3em;
  justify-content: space-between;

  :hover {
    background-color: ${Colors.SeaLighter};
  }

  :active {
    background-color: ${Colors.SeaLighter};
  }

  background-color: ${(props) =>
    props.isSelected ? Colors.SeaLighter : 'inherit'};
`

const ItemLabel = styled.div`
  position: relative;
`

const QItemInfo = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`

const messageName = isSwedenPortal
  ? 'dcse-start-update-labels'
  : 'dcno-start-update-labels'

const QueueItem = ({ path, flow, hasPendingChat, t }: QueueItemProps) => {
  // QueueItem block

  // Checks if we have any labels set on this flow. otherwise set a empty flow
  const labelStartState: { [key: string]: any } = flow.data.labels
    ? flow.data.labels
    : labels.reduce((o, label) => Object.assign(o, { [label]: null }), {})
  const [allLabels, setLabels] = useState(labelStartState)
  const [showDropdown, setShowDropdown] = useState(false)
  const [availableDropdown, setAvailableDropdown] = useState(true)

  const {
    flowDefinitionId,
    flowId,
    referenceId,
    flowNumber,
    referenceName,
    data
  } = flow
  const title = referenceId || flowNumber

  const createdAtDate = formatDateTime(new Date(flow.createdAt))
  const inboxDate = flow.data.inboxDate
    ? formatDateTime(new Date(flow.data.inboxDate))
    : ''

  // May change this to include other processes, but MVP is DC only for now.
  // Requires the update-state-labels message-task to work.
  const hasLabels =
    flowDefinitionId === 'document-control' ||
    flowDefinitionId === 'document-control-se'

  const pendingAction = data.hasPendingDocument

  const updateLabel = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    labelColor: string
  ) => {
    setAvailableDropdown(!availableDropdown) // prevents multiple presses on the labels on the same case by locking the dropdownmeny --> avoids racecondition
    e.preventDefault()
    setShowDropdown(false)

    const labelActive = allLabels[labelColor] ? !allLabels[labelColor] : true

    // set in database
    task
      .triggerMessage(flowId, messageName, {
        labelName: labelColor,
        labelState: labelActive
      })
      .then(() => {
        // dispaches the labels and sets them on the screen
        setAvailableDropdown(true)
        const newState = { ...allLabels }
        newState[labelColor] = labelActive
        setLabels(newState)
      })
  }

  const kycLabel = flow.data.labels && flow.data.labels.customerDeclaration

  const toggleDropdownMenu = (e: React.MouseEvent) => {
    e.preventDefault()
    setShowDropdown(!showDropdown)
  }

  const date =
    flowDefinitionId === 'document-control' ? inboxDate : createdAtDate

  return (
    <ItemNavLink
      activeClassName="active"
      to={`/inbox/${path}/${flowDefinitionId}/${flowId}`}
    >
      <Container pendingAction={pendingAction} pendingChat={hasPendingChat}>
        <Heading>
          <p>{referenceName || data.referenceName}</p>
          <QItemInfo>
            <div>{date}</div>
            {hasLabels && (
              <>
                {availableDropdown && (
                  <DropdownButton onClick={toggleDropdownMenu}>
                    <Icons.Overflow style={{ height: '100%' }} />
                  </DropdownButton>
                )}
                {showDropdown && (
                  <DropdownContainer>
                    {labels.map((label: string) => (
                      <DropdownItem
                        key={label}
                        isSelected={!!allLabels[label]}
                        onClick={(e) => updateLabel(e, label)}
                      >
                        <ItemLabel>
                          <Mark color={label} />
                        </ItemLabel>
                        <div>
                          {allLabels[label] ? label.toUpperCase() : label}
                        </div>
                      </DropdownItem>
                    ))}
                  </DropdownContainer>
                )}
              </>
            )}
          </QItemInfo>
        </Heading>
        <Body>
          <div>
            <Title>{title}</Title>
            <p>{t(flowDefinitionId)}</p>
          </div>
          <Notifications>
            {kycLabel && <Mark color="green" />}
            {Object.keys(allLabels)
              .filter((label) => label !== 'customerDeclaration')
              .map(
                (label) =>
                  allLabels[label] && <Mark key={label} color={label} />
              )}
            {hasPendingChat && <Icons.Chat color={Colors.SeaDark} size="16" />}
            {pendingAction && (
              <Icons.ActiveCircle color={Colors.Orange} size="16" />
            )}
          </Notifications>
        </Body>
      </Container>
    </ItemNavLink>
  )
}

QueueItem.defaultProps = {
  flow: {},
  hasPendingChat: false
}

export default QueueItem
