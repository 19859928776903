import Branding from './plugins/Branding'
import MainLogo from './plugins/MainLogo'
import CustomComponentsSE from './configuration/customComponentsSE'
import getFormattedSimpleSearchFeatureToggles from './utils/featureTogglesFormatter'
import customComponentsNO from './configuration/customComponentsNO'

const { featureToggles = [] } = window.stacc_env

const isSwedenPortal = featureToggles.includes('se-portal')

const CustomComponents = isSwedenPortal
  ? CustomComponentsSE
  : customComponentsNO

const SearchFieldsNO = [
  'referenceId',
  ...getFormattedSimpleSearchFeatureToggles()
]

const SearchFieldsSE = [
  'referenceId',
  ...getFormattedSimpleSearchFeatureToggles()
]

const SearchFields = isSwedenPortal ? SearchFieldsSE : SearchFieldsNO

export { Branding, MainLogo, CustomComponents, SearchFields }
