import { TrendDownIcon } from '../../icons/TrendDownIcon'
import { formatMoney } from '../../utils/formatMoney'
import AffordabilityAnswers from '../../types/AffordabilityAnswers'

export type DebtCardProps = {
  affordabilityAnswers: AffordabilityAnswers
}

export function DebtCard({ affordabilityAnswers }: DebtCardProps) {
  const totalDebt = calculateTotalDebt(affordabilityAnswers)
  return (
    <div className="row-span-3">
      <div className="rounded-lg mb-6 border border-gray-300 overflow-hidden">
        <div className="bg-negative-50 text-negative-800 p-4 border-b border-gray-300 flex gap-3">
          <TrendDownIcon />
          <h2 className="text-md font-medium">Gjeld</h2>
        </div>
        <div className="text-sm divide-y divide-gray-200 p-4">
          <div className="py-3 flex justify-between">
            <span className="pr-3">Husstandends totale boliggjeld</span>
            <span>{formatMoney(affordabilityAnswers['houseMortgage'])}</span>
          </div>
          <div className="py-3 flex justify-between">
            <span className="pr-3">Limit boligkreditt</span>
            <span>
              {formatMoney(affordabilityAnswers['houseMortgageFlexibleCredit'])}
            </span>
          </div>
          <div className="py-3 flex justify-between">
            <span className="pr-3">Kredittkortgrense</span>
            <span>
              {formatMoney(affordabilityAnswers['totalCreditCardLimit'])}
            </span>
          </div>
          <div className="py-3 flex justify-between">
            <span className="pr-3">Restgjeld forbrukslån</span>
            <span>{formatMoney(affordabilityAnswers['consumerLoan'])}</span>
          </div>
          <div className="py-3 flex justify-between">
            <span className="pr-3">Studielån</span>
            <span>{formatMoney(affordabilityAnswers['studentLoan'])}</span>
          </div>
          <div className="py-3 flex justify-between">
            <span className="pr-3">Bil-, båtlån, MC lån</span>
            <span>{formatMoney(affordabilityAnswers['carBoatLoan'])}</span>
          </div>
          {affordabilityAnswers['hasOutstandingCarLoan'] === 'no' ? (
            <div className="py-3 flex justify-between">
              <span className="pr-3">
                Eksisterende billån blir innfridd ved kjøp
              </span>
              <span>
                {affordabilityAnswers['hasOutstandingCarLoan'] === 'no'
                  ? 'Nei'
                  : 'Ja'}
              </span>
            </div>
          ) : (
            <div className="py-3 flex justify-between">
              <span className="pr-3">
                Restgjeld av billån innfridd ved kjøp
              </span>
              <span>
                {formatMoney(
                  '-' + affordabilityAnswers['carLoanOutstandingAmount']
                )}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

function calculateTotalDebt(affordabilityAnswers: any) {
  let totalDebt = 0

  if (affordabilityAnswers) {
    if (affordabilityAnswers['houseMortgage']) {
      totalDebt += affordabilityAnswers['houseMortgage']
    }
    if (affordabilityAnswers['consumerLoans']) {
      totalDebt += affordabilityAnswers['consumerLoans']
    }
    if (affordabilityAnswers['studentLoan']) {
      totalDebt += affordabilityAnswers['studentLoan']
    }
    if (affordabilityAnswers['carBoatLoan']) {
      totalDebt += affordabilityAnswers['carBoatLoan']
    }
    if (affordabilityAnswers['hasOutstandingCarLoan'] === 'yes') {
      totalDebt -= affordabilityAnswers['carLoanOutstandingAmount']
    }
  }

  return totalDebt
}
