import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { CenteredSpinner } from '@flow/icons'

import fileService from '../../services/files'
import ImageViewer from './documentsViewer/ImageViewer'
import PdfViewer from './documentsViewer/PdfViewer'
import { FileDisplayProps } from '../../types/common/common'

const FileDisplay = ({ fileId }: FileDisplayProps) => {
  const [file, setFile] = useState<any>(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    fileService.getFile(fileId).then((result) => {
      if (result) {
        setFile({ fileId, type: result.type, file: result.file })
        setIsLoading(false)
      } else {
        setFile(null)
        setIsLoading(false)
      }
    })
  }, [])

  if (isLoading) {
    return <CenteredSpinner />
  }

  if (!file) {
    return null
  }

  if (file.type.includes('image')) {
    return <ImageViewer file={file.file} type={file.type} />
  }

  if (file.type.includes('pdf')) {
    return <PdfViewer file={file.file} fileId={file.id} />
  }

  return null
}

FileDisplay.propTypes = {
  fileId: PropTypes.string.isRequired
}

export default FileDisplay
