const { featureToggles = [] } = window.stacc_env

const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1)

const getEsigningActionTitle = (task) => {
  if (task.data && task.data.status) {
    return `esign_${capitalize(task.data.status)}`
  }
  if (task.context && task.context.temporaryStatus) {
    return `esign_${capitalize(task.context.temporaryStatus)}`
  }
  return 'E-signing'
}

const taskIsSeAndCustomerContract = (task) =>
  featureToggles.includes('se-portal') &&
  task.status === 'completed' &&
  (task.context.conditionType === 'CUSTOMER_CONTRACT' ||
    task.context.conditionType === 'CUSTOMER_PARTNER_PRINT_CONTRACT')

export const tasksFilter = (task, index, self) => {
  if (
    task.status === 'completed' &&
    (task.taskType === 'sofp-provide-information' ||
      task.taskType === 'cdp-provide-information' ||
      task.taskType === 'cdp-provide-information-with-co-applicant' ||
      task.taskType === 'sofb-provide-information' ||
      task.taskType === 'cdb-provide-information' ||
      task.taskType === 'cdp-se-provide-information' ||
      task.taskType === 'cdp-se-provide-information-with-co-applicant' ||
      task.taskType === 'cdbse-provide-information') &&
    task.context.fileId
  ) {
    return task
  }

  if (task.taskCategory !== 'user-task') {
    return null
  }

  if (task.context && task.context.conditionType) {
    /**
     * CUSTOMER_CONTRACT = E-sign version, CUSTOMER_PARTNER_PRINT_CONTRACT = print version.
     * This 'if' should filter out any previously esigned CUSTOMER_CONTRACT or uploaded CUSTOMER_PARTNER_PRINT_CONTRACT
     */
    if (taskIsSeAndCustomerContract(task)) {
      const customerPartnerContractPreviouslyCompleted = self.find(
        (i) =>
          i.context &&
          (i.context.conditionType === 'CUSTOMER_PARTNER_PRINT_CONTRACT' ||
            i.context.conditionType === 'CUSTOMER_CONTRACT') &&
          new Date(i.createdAt) > new Date(task.createdAt)
      )

      if (customerPartnerContractPreviouslyCompleted) {
        return null
      }

      return task
    }

    const newerMatchingTask = self.find(
      (i) =>
        i.context &&
        i.context.conditionType === task.context.conditionType &&
        new Date(i.createdAt) > new Date(task.createdAt)
    )

    if (newerMatchingTask) {
      return null
    }
    return task
  }

  if (task.status === 'pending' || task.status === 'failed') {
    return task
  }

  return null
}

export const taskMap = (task) => {
  if (
    task.status === 'completed' &&
    task.taskType === 'sofp-provide-information'
  ) {
    return {
      title: 'Midlenes opprinnelse',
      actionTitle: 'View'
    }
  }

  if (
    task.status === 'completed' &&
    task.taskType === 'sofb-provide-information'
  ) {
    return {
      title: 'Midlenes opprinnelse',
      actionTitle: 'View'
    }
  }

  if (
    task.status === 'completed' &&
    (task.taskType === 'cdp-provide-information' ||
      task.taskType === 'cdp-provide-information-with-co-applicant' ||
      task.taskType === 'cdb-provide-information')
  ) {
    return {
      title: 'Kundeerklæring',
      actionTitle: 'View'
    }
  }

  if (
    task.status === 'completed' &&
    (task.taskType === 'cdp-se-provide-information' ||
      task.taskType === 'cdp-se-provide-information-with-co-applicant' ||
      task.taskType === 'cdbse-provide-information')
  ) {
    return {
      title: 'Customer Declaration',
      actionTitle: 'View'
    }
  }

  if (task.context && task.context.conditionType) {
    if (
      task.taskType === 'dc-e-sign-document' ||
      task.taskType === 'dcse-e-sign-document'
    ) {
      return {
        title: task.context.conditionType,
        actionTitle: getEsigningActionTitle(task)
      }
    }

    if (
      task.taskType === 'upload-signed-document' &&
      (task.context.conditionType === 'SOURCE_OF_FUNDS' ||
        task.context.conditionType === 'CUSTOMER_DECLARATION' ||
        task.context.conditionType === 'CO_CUSTOMER_DECLARATION')
    ) {
      return {
        title: task.context.conditionType,
        actionTitle:
          task.status === 'completed' ? 'View' : 'Waiting on customer'
      }
    }

    if (
      task.taskType === 'dcse-upload-signed-document' &&
      task.context.conditionType === 'SOURCE_OF_FUNDS'
    ) {
      return {
        title: task.context.conditionType,
        actionTitle:
          task.status === 'completed' ? 'View' : 'Waiting on customer'
      }
    }

    if (
      task.taskType === 'dcse-upload-signed-document' &&
      (task.context.conditionType === 'CUSTOMER_DECLARATION_SE' ||
        task.context.conditionType === 'CO_CUSTOMER_DECLARATION_SE')
    ) {
      if (task.status === 'completed') {
        return {
          title: task.context.conditionType,
          actionTitle: 'View'
        }
      }
      if (task.context.isAnswered && task.context.path) {
        return {
          title: task.context.conditionType,
          actionTitle: 'Go to assessment'
        }
      }

      return {
        title: task.context.conditionType,
        actionTitle: 'Waiting on customer'
      }
    }

    return {
      title: task.context.conditionType,
      actionTitle: task.status === 'completed' ? 'View' : task.taskType
    }
  }

  if (
    task.taskType === 'dcse-assess-customer-declaration' &&
    !task.context.customer.isMainApplicant
  ) {
    return {
      title: 'Co Customer Declaration Assessment',
      actionTitle: 'Start'
    }
  }

  if (
    task.taskType === 'dc-assess-customer-declaration' &&
    task.context.customer.isMainApplicant === false
  ) {
    return {
      title: 'Co Customer Declaration Assessment',
      actionTitle: 'Start'
    }
  }

  return {
    title: task.taskType,
    actionTitle: 'Start'
  }
}
