import { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import { Icons } from '@flow/icons'
import { PrimaryButton, TransparentButton } from '@flow/buttons'
import { FontStyles, Colors } from '@flow/style'

import taskService from '../../services/task'
import ConfirmationView from './ConfirmationView'
import { PendingTaskProps } from '../../types/common/common'

const TaskContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  ${FontStyles.LargeStrong};
`

const TaskDescription = styled.div`
  display: flex;
  align-items: center;
  min-height: 35px;

  p {
    margin: 0;
    padding-left: 20px;
  }
`

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
`

const ButtonGroup = styled.div`
  display: flex;
`

const PendingTask = ({
  title,
  taskId,
  taskType,
  actionTitle,
  path,
  disabled,
  context,
  t
}: PendingTaskProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [displayRemoveTaskModal, setDisplayRemoveTaskModal] = useState(false)
  const [displayCompleteTaskModal, setDisplayCompleteTaskModal] =
    useState(false)
  const [displaySkipUploadTaskModal, setDisplaySkipUploadTaskModal] =
    useState(false)

  // this.onCompleteTask = this.onCompleteTask.bind(this);
  // this.onRemoveTask = this.onRemoveTask.bind(this);
  // this.onSkipUploadTask = this.onSkipUploadTask.bind(this);

  function onCompleteTask() {
    setIsSubmitting(true)

    taskService.saveTask(
      taskId,
      { status: 'Signed' },
      () => {
        taskService.completeTask(
          taskId,
          () => {
            setDisplayRemoveTaskModal(false)
            setIsSubmitting(false)
          },
          (err: Error) => {
            setDisplayRemoveTaskModal(false)
            setIsSubmitting(false)
            console.error('Error while completing task', err)
          }
        )
      },
      (saveError: Error) => {
        setIsSubmitting(false)
        console.error('Error while saving task with Signed status', saveError)
      }
    )
  }

  function onRemoveTask() {
    setIsSubmitting(true)

    taskService.saveTask(
      taskId,
      { status: 'removed' },
      () => {
        taskService.completeAsRemovedTask(
          taskId,
          () => {
            setDisplayRemoveTaskModal(false)
            setIsSubmitting(false)
          },
          (err: Error) => {
            console.error('Error while removing task', err)
            setDisplayRemoveTaskModal(false)
            setIsSubmitting(false)
          }
        )
      },
      (saveError: Error) => {
        setIsSubmitting(false)
        console.error('Error while saving task with removed status', saveError)
      }
    )
  }

  function onSkipUploadTask() {
    setIsSubmitting(false)

    taskService.saveTask(
      taskId,
      { status: 'completed' },
      () => {
        taskService.completeTaskWithData(
          taskId,
          { skipUpload: true, fileId: '' },
          () => {
            setDisplaySkipUploadTaskModal(false)
            setIsSubmitting(false)
          },
          (err: Error) => {
            setDisplaySkipUploadTaskModal(false)
            setIsSubmitting(false)
            console.error('Error while completing task', err)
          }
        )
      },
      (saveError: any) => {
        setIsSubmitting(false)
        console.error('Error while saving task with Signed status', saveError)
      }
    )
  }
  const newPath = context.path || path
  const isEsignTask =
    taskType === 'dc-e-sign-document' ||
    taskType === 'dcse-e-sign-document' ||
    taskType === 'dcse-externally-sign-document'
  const isInvoiceDocumentRequirement =
    taskType === 'dcse-upload-signed-document' &&
    context.conditionType === 'INVOICE_SE' // Is invoice requirement
  const actionButton = (
    <Link to={newPath}>
      <PrimaryButton disabled={disabled || isEsignTask}>
        {actionTitle}
      </PrimaryButton>
    </Link>
  )

  return (
    <TaskContainer>
      <TaskDescription>
        <Icons.ActiveCircle />
        <p>{title}</p>
      </TaskDescription>
      <ActionContainer>
        {isEsignTask && (
          <ButtonGroup>
            <TransparentButton
              style={{ display: 'flex', alignItems: 'center' }}
              title={`${t('Complete')} ${title}`}
              type="button"
              onClick={() =>
                setDisplayCompleteTaskModal(!displayCompleteTaskModal)
              }
            >
              <Icons.SuccessCircle color={Colors.Grass} />
            </TransparentButton>
            <TransparentButton
              title={`${t('Remove')} ${title}`}
              type="button"
              onClick={() => setDisplayRemoveTaskModal(!displayRemoveTaskModal)}
            >
              <Icons.Trashcan color={Colors.Ferrari} />
            </TransparentButton>
          </ButtonGroup>
        )}
        {isInvoiceDocumentRequirement && (
          <TransparentButton
            title={`${t('Skip uploading')} ${title}`}
            type="button"
            onClick={() =>
              setDisplaySkipUploadTaskModal(!displaySkipUploadTaskModal)
            }
          >
            <Icons.Trashcan color={Colors.Ferrari} />
          </TransparentButton>
        )}
        {actionButton}
      </ActionContainer>
      {displayRemoveTaskModal && (
        <ConfirmationView
          title={`${t('Remove')} ${title}`}
          message={`${t(
            'Are you sure you want to remove the document requirement'
          )} "${title}"?`}
          isConfirming={isSubmitting}
          onConfirm={onRemoveTask}
          onAbort={() => setDisplayRemoveTaskModal(false)}
          onClose={() => setDisplayRemoveTaskModal(false)}
        />
      )}
      {displaySkipUploadTaskModal && (
        <ConfirmationView
          title={`${t('Remove')} ${title}`}
          message={`${t(
            'Are you sure you want to remove the document requirement'
          )} "${title}"?`}
          isConfirming={isSubmitting}
          onConfirm={onSkipUploadTask}
          onAbort={() => setDisplaySkipUploadTaskModal(false)}
          onClose={() => setDisplaySkipUploadTaskModal(false)}
        />
      )}
      {displayCompleteTaskModal && (
        <ConfirmationView
          title={`${t('Complete')} ${title}`}
          message={`${t(
            'Are you sure you want to complete the document requirement'
          )} "${title}"?`}
          isConfirming={isSubmitting}
          onConfirm={onCompleteTask}
          onAbort={() => setDisplayCompleteTaskModal(false)}
          onClose={() => setDisplayCompleteTaskModal(false)}
        />
      )}
    </TaskContainer>
  )
}

PendingTask.defaultProps = {
  path: '',
  actionTitle: 'Åpne',
  disabled: false
}

export default withTranslation('inbox')(PendingTask)
