import { useMemo, useState } from 'react'
import { classNames } from './classNames'
import { FilePreview } from './PreviewFile'
import { Chevron } from '../../icons/chevron'
import { useFlowContext } from './useFlowContext'

type FileRowProps = {
  data: {
    file: any
    mimeType: string
    fileId: string
    type: string
    fileName: string
    created: Date
  }
}

const documentTypes = {
  invoice: 'Invoice',
  offer: 'Offer',
  quote: 'Quote',
  contract: 'Contract',
  other: 'Other'
}

export function FileRow({ data }: FileRowProps) {
  const [open, setOpen] = useState(false)
  const { t, flow } = useFlowContext()

  const fileBlob = useMemo(() => {
    const file = new Blob([data.file], { type: data.mimeType })
    return URL.createObjectURL(file)
  }, [data.file, data.mimeType])

  const isImage = data.mimeType.includes('image')
  const isPdf = data.mimeType.includes('pdf')

  async function handleClick() {
    if (!isImage && !isPdf) {
      const a = document.createElement('a')
      a.href = fileBlob
      a.download = data.fileName
      a.click()
    } else {
      setOpen(!open)
    }
  }

  const dateCreated = new Date(
    data?.created ?? data?.created
  ).toLocaleDateString('no-NO', {
    hour: 'numeric',
    minute: 'numeric'
  })

  const fileName =
    data.fileName.length > 25
      ? `${data.fileName.substring(0, 30)}...`
      : data.fileName

  return (
    <>
      <tr className=" border-misc-ui-separator border-t">
        <td onClick={() => handleClick()} className=" cursor-pointer pl-5 py-3">
          {(t(data.type) || '').toUpperCase()}
        </td>
        <td onClick={() => handleClick()} className="cursor-pointer">
          {fileName}
        </td>
        <td
          onClick={() => handleClick()}
          className="cursor-pointer text-right pr-2"
        >
          {dateCreated}
        </td>
        <td
          onClick={() => handleClick()}
          className=" cursor-pointer w-3 text-sea-30 pr-5"
        >
          {isImage || isPdf ? (
            <Chevron
              className={classNames(
                open ? 'rotate-180' : 'rotate-0',
                'text-blue-500 transform duration-150 ease-in-out'
              )}
            />
          ) : (
            <button onClick={handleClick}>{t('download')}</button>
          )}
        </td>
      </tr>
      {open && (
        <tr>
          <FilePreview file={fileBlob} mimeType={data.mimeType} />
        </tr>
      )}
    </>
  )
}
