export const validateDocument = (doc) => {
  if (!doc.audit) {
    return false
  }
  const missingComment =
    doc.audit === 'rejected' && (!doc.comment || doc.comment.length < 1)
  if (missingComment) {
    return false
  }
  return true
}

export const mapTaskToDocuments = (task) => {
  const { context, data } = task

  return context.map((c) => {
    if (data && data.documents) {
      const match = data.documents.find((d) => d.name === c.conditionType)
      if (match) {
        return {
          fileId: c.fileId,
          name: match.name,
          audit: match.audit,
          comment: match.comment,
          valid: validateDocument(match),
          approvalComment: c.comment,
          isApprovalCommentOpen: true
        }
      }
    }
    return {
      fileId: c.fileId,
      name: c.conditionType,
      audit: 'approved',
      comment: null,
      valid: true,
      approvalComment: c.comment,
      isApprovalCommentOpen: true
    }
  })
}
