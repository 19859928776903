import { LoadingSpinner } from '../../icons/LoadingSpinner'

type FilePreviewProps = {
  file: any
  mimeType: string
}
export function FilePreview({ file, mimeType }: FilePreviewProps) {
  const isImage = mimeType.includes('image')
  const isPdf = mimeType.includes('pdf')

  return (
    <td colSpan={5}>
      {isImage && <img src={file} alt="file" className="w-full" />}
      {isPdf && (
        <iframe className={'h-screen w-full'} title="file" src={file} />
      )}
    </td>
  )
}
