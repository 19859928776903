const businessStatus = [
  { label: 'business-status-assessment', value: 'assessment' },
  {
    label: 'business-status-awaiting-customer-answer',
    value: 'awaiting-customer-answer'
  },
  { label: 'business-status-completed', value: 'completed' },
  { label: 'business-status-generate-document', value: 'generate-document' },
  { label: 'business-status-send-sms', value: 'send-sms' },
  { label: 'business-status-send-message', value: 'send-sms/e-email' },
  {
    label: 'business-status-get-company-information',
    value: 'get-company-information'
  },
  { label: 'business-status-get-key-people', value: 'lookup-company' },
  {
    label: 'business-status-start-doc-control-assessment',
    value: 'adding-assessment-to-document-control-processes'
  },
  { label: 'business-status-rejected-audit', value: 'rejected-audit' },
  { label: 'business-status-archiving', value: 'archiving' },
  { label: 'business-status-audit', value: 'audit' },
  {
    label: 'business-status-select-post-control',
    value: 'select-post-control'
  },
  { label: 'business-status-post-control', value: 'post-control' },
  {
    label: 'business-status-select-document-requirements',
    value: 'selecting-document-requirements'
  },
  {
    label: 'business-status-identify-document-requirements',
    value: 'identifying-document-requirements'
  },
  {
    label: 'business-status-collecting-document-requirements',
    value: 'collecting-document-requirements'
  },
  {
    label: 'business-status-compress-archive-documents',
    value: 'generate-archive-document'
  },
  {
    label: 'business-status-process-document-for-archive',
    value: 'generate-archive-document'
  },
  {
    label: 'business-status-merge-archive-documents',
    value: 'generate-archive-documentt'
  },
  {
    label: 'business-status-send-completion-message',
    value: 'send-message-to-salesperson'
  },
  {
    label: 'business-status-update-ncvs-data',
    value: 'updating-with-new-data-from-ncvs'
  },
  { label: 'business-status-start-payout', value: 'start-payout' }
]

export default businessStatus
