import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'
import axios from 'axios'

import { Colors, FontStyles, Headings } from '@flow/style'
import { PrimaryProgressButton, SecondaryButton } from '@flow/buttons'

import {
  ScreenContainer,
  LeftContent,
  RightContent,
  GroupPadded,
  GroupPaddedGrow,
  ButtonGroup,
  Error
} from '../../common/TaskViewContainerStyle'
import Checkbox from '../../common/Checkbox'
import UploadMultipleScreen from '../../common/UploadMultipleForm'
import DelayedApprovalContext from './DelayedApprovalContext'
import { render } from 'react-dom'
import { set } from 'date-fns'
import { PostControl, PostControlScreenProps } from '../../../types/postControl'

const CheckboxWrapper = styled.div`
  margin-bottom: 10px;
`

const List = styled.div`
  margin-top: 10px;
`

const EmptyError = styled.p`
  ${FontStyles.Small};
  height: 16px;
  margin: 5px 0 15px 0;
`

const draggingStyle = {
  border: 'dashed 4px',
  borderColor: Colors.Sea,
  boxSizing: 'border-box',
  MozBoxSizing: 'border-box',
  WebkitBoxSizing: 'border-box',
  backgroundColor: 'rgba(255,255,255,.8)'
}

const draggingTextStyle = {
  fontSize: '22px',
  display: 'flex',
  alignItems: 'center',
  flex: 1,
  justifyContent: 'center',
  height: '100%'
}

const PostControlScreen = ({
  close,
  onComplete,
  onSave,
  t,
  task,
  updateCase
}: PostControlScreenProps) => {
  const [postControlList, setPostControlList] = useState<PostControl[]>([])
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showError, setShowError] = useState(false)
  const [dragging, setDragging] = useState(0)
  const [files, setFiles] = useState<File[]>([])
  const [displayErrors, setDisplayErrors] = useState(false)

  // this.onSelect = this.onSelect.bind(this);
  // this.handleSave = this.handleSave.bind(this);
  // this.handleComplete = this.handleComplete.bind(this);

  useEffect(() => {
    const postControlList =
      task.data && task.data.postControlList
        ? task.data.postControlList
        : task.context.postControlList

    const files = task.data && task.data.files ? task.data.files : []
    setFiles(files)
    setPostControlList(postControlList)
  }, [])

  const onSelect = (field: string, newValue: boolean) => {
    const duplicatePostControlList = postControlList.map((el: PostControl) => {
      if (el.name === field) {
        return { ...el, value: newValue }
      }
      return el
    })
    setPostControlList(duplicatePostControlList)
  }

  function onFileAdded(filesToAdd: FileList) {
    const newFiles = []
    for (let i = 0; i < filesToAdd.length; i++) {
      const file = filesToAdd[i]
      const existsAlready = files.find((f) => f.name === file.name)
      if (!existsAlready) {
        newFiles.push(file)
      }
    }
    setFiles(newFiles)
  }

  function onFileRemoved(fileName: string) {
    const indexOfRemovedFile = files.findIndex((f) => f.name === fileName)
    setFiles([
      ...files.slice(0, indexOfRemovedFile),
      ...files.slice(indexOfRemovedFile + 1, files.length)
    ])
  }

  const handleSave = () => {
    setIsSubmitting(true)

    onSave(
      task.taskId,
      { postControlList },
      () => {
        updateCase()
        close()
        setIsSubmitting(false)
      },
      () => {
        setIsSubmitting(false)
      }
    )
  }

  const handleComplete = () => {
    const checkIfAllIsTrue = postControlList.filter(
      (el: PostControl) => el.value === false
    )

    if (checkIfAllIsTrue.length > 0) {
      setShowError(true)
      setIsSubmitting(false)
      return
    }
    setShowError(false)
    setIsSubmitting(true)

    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }

    const uploadFilePromises = files.map(async (file: File) => {
      const formData = new FormData()
      formData.append('file', file)

      return new Promise((resolve, reject) => {
        axios
          .post('/api/files/', formData, config)
          .then((res) => {
            resolve(res.data)
          })
          .catch((err) => {
            console.error(err)
            reject(err)
          })
      })
    })

    Promise.all(uploadFilePromises).then((fileUploadResults) => {
      const fileIds = fileUploadResults.map((f: any) => f.id)

      onComplete(
        task.taskId,
        { postControlList, fileIds },
        () => {
          updateCase()
          close()
          setIsSubmitting(false)
        },
        () => {
          setIsSubmitting(false)
        }
      )
    })
  }

  function handleFileDrop(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault()
    e.stopPropagation()
    setDragging(0)

    onFileAdded(e.dataTransfer.files)
  }

  function handleDragOver(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault()
    e.stopPropagation()
  }

  const { context } = task

  const hasDocsWithDelayedApproval =
    context &&
    context.docsWithDelayedApproval &&
    context.docsWithDelayedApproval.length > 0

  const controlList = postControlList.map((el: PostControl) => (
    <CheckboxWrapper key={uuidv4()}>
      <Checkbox
        id={el.name}
        name={el.name}
        label={t(el.name)}
        value={el.value + ''}
        checked={el.value}
        onChange={(e) => onSelect(el.name, e.target.checked)}
      />
    </CheckboxWrapper>
  ))

  return (
    <ScreenContainer>
      <LeftContent
        onDrop={(e) => handleFileDrop(e)}
        onDragOver={(e) => handleDragOver(e)}
        onDragEnter={() => {
          setDragging(dragging + 1)
        }}
        onDragLeave={() => {
          setDragging(dragging - 1)
        }}
        style={{
          borderStyle: dragging ? 'dashed' : '',
          borderWidth: dragging ? '4px' : '',
          borderColor: Colors.Sea,
          boxSizing: 'border-box',
          MozBoxSizing: 'border-box',
          WebkitBoxSizing: 'border-box',
          backgroundColor: 'rgba(255,255,255,.8)'
        }}
        // style={dragging ? draggingStyle : {}}
      >
        {!!dragging && (
          <p style={draggingTextStyle}>{t('Drop files here to upload them')}</p>
        )}
        {!dragging && (
          <GroupPadded>
            <GroupPadded>
              <Headings.H6>{t('checklist')}</Headings.H6>
              <List>{controlList}</List>
            </GroupPadded>
            <GroupPadded>
              <Headings.H6>{t('Uploaded documents')}</Headings.H6>
              <UploadMultipleScreen
                files={files}
                onFileAdded={(file: FileList) => onFileAdded(file)}
                onFileRemoved={(fileName: string) => onFileRemoved(fileName)}
              />
            </GroupPadded>
          </GroupPadded>
        )}
      </LeftContent>
      <RightContent>
        <GroupPaddedGrow>
          {hasDocsWithDelayedApproval && (
            <DelayedApprovalContext
              delayedApprovals={context.docsWithDelayedApproval}
            />
          )}
        </GroupPaddedGrow>
        <GroupPadded>
          {showError ? (
            <Error>{t('all-elements-are-not-controlled')}</Error>
          ) : (
            <EmptyError />
          )}
          <ButtonGroup>
            <SecondaryButton
              onClick={() => handleSave()}
              disabled={isSubmitting}
            >
              {t('Save and close')}
            </SecondaryButton>
            <PrimaryProgressButton
              type="submit"
              onClick={() => handleComplete()}
              disabled={isSubmitting}
              isLoading={isSubmitting}
            >
              {t('Complete')}
            </PrimaryProgressButton>
          </ButtonGroup>
        </GroupPadded>
      </RightContent>
    </ScreenContainer>
  )
}

PostControlScreen.defaultProps = {
  onComplete: () => {},
  onSave: () => {},
  close: () => {},
  updateCase: () => {}
}

export default withTranslation('inbox')(PostControlScreen)
