export function PalaceIcon() {
  return (
    <svg width="25" height="23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g
        clipPath="url(#a)"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#00257A"
      >
        <path d="M10.747 6h3.509l2.571 9H8.176l2.571-9Zm1.509 2-1.429 5h3.349l-1.429-5h-.491ZM3.5 22v-6h-2v8h6v-8h-2v6h-2ZM19.5 22v-6h-2v8h6v-8h-2v6h-2ZM10.379 17.879A3 3 0 0 1 15.5 20v3h-2v-3a1 1 0 0 0-2 0v3h-2v-3a3 3 0 0 1 .879-2.121Z" />
        <path d="M9.5 15h-2v-2h2v2ZM17.5 15h-2v-2h2v2ZM18.5 24h-12v-2h12v2Z" />
        <path d="m4.463 8.226.851 1.194c.503.704.993 1.142 1.486 1.582.142.127.283.253.426.386.305.286.634.626.879 1.057.256.452.395.96.395 1.555 0 1.008-.594 1.787-1.33 2.27-.734.48-1.683.73-2.67.73-.955 0-1.905-.204-2.652-.67C1.072 15.843.5 15.05.5 14c0-1.28.694-2.025 1.328-2.606.114-.105.227-.206.34-.306.52-.463 1.023-.91 1.494-1.634l.8-1.228Zm.06 3.369c-.384.422-.76.754-1.069 1.027l-.274.246c-.535.49-.68.73-.68 1.132 0 .233.1.44.409.633.34.213.89.367 1.591.367.67 0 1.221-.173 1.573-.403.35-.23.427-.45.427-.597 0-.261-.055-.428-.135-.57-.092-.162-.242-.335-.505-.581-.096-.09-.206-.187-.327-.294-.29-.258-.64-.57-1.01-.96ZM20.463 8.226l.851 1.194c.503.704.993 1.142 1.486 1.582.142.127.283.253.426.386.305.286.634.626.879 1.057.256.452.395.96.395 1.555 0 1.008-.594 1.787-1.33 2.27-.734.48-1.683.73-2.67.73-.955 0-1.905-.204-2.652-.67-.776-.486-1.348-1.28-1.348-2.33 0-1.28.694-2.025 1.328-2.606.114-.105.227-.206.34-.306.52-.463 1.023-.91 1.494-1.634l.8-1.228Zm.06 3.369c-.384.422-.76.754-1.069 1.027l-.274.246c-.535.49-.68.73-.68 1.132 0 .233.1.44.409.633.34.213.89.367 1.591.367.67 0 1.221-.173 1.573-.403.35-.23.427-.45.427-.597 0-.261-.055-.428-.135-.57-.092-.162-.242-.335-.505-.581-.096-.09-.206-.187-.326-.294-.29-.258-.642-.57-1.01-.96ZM12.5 4a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm-3 1a3 3 0 1 1 6 0 3 3 0 0 1-6 0Z" />
        <path d="M13.484 0v4h-2V0h2ZM21.5 7v4h-2V7h2ZM5.5 7v4h-2V7h2Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" transform="translate(.5)" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
