import { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { getStakeholder } from '../../services/stakeholders'
import ConfirmationView from '../common/ConfirmationView'
import Stakeholder from './Stakeholder'
import {
  DeleteUserViewProps,
  DeleteUserViewParams,
  Stakeholder as StakeholderUser
} from '../../types/adminDealers'

const DeleteUserView = ({ deleteHandler }: DeleteUserViewProps) => {
  const history = useHistory()
  const { userId } = useParams<DeleteUserViewParams>()

  const [isSubmitting, setIsSubmitting] = useState(false)

  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(false)
  const [user, setUser] = useState<StakeholderUser>()

  const onConfirm = async () => {
    setIsSubmitting(true)
    await deleteHandler(userId)
    setIsSubmitting(false)
    history.push('/admin/dealers')
  }

  useEffect(() => {
    setIsLoading(true)
    setError(false)
    getStakeholder(userId)
      .then((result) => {
        setUser(result.data)
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
        setError(true)
      })
  }, [userId])

  return (
    !isLoading && (
      <ConfirmationView
        title={`Deleting user ${user?.name}`}
        message={
          error || !user ? (
            `An error occurred when deleting a stakeholder: ${error}`
          ) : (
            <>
              <span>Are you sure you want to delete the following user?</span>
              <Stakeholder stakeholder={user} />
            </>
          )
        }
        isConfirming={isSubmitting}
        onConfirm={onConfirm}
        onAbort={() => history.push('/admin/dealers')}
        onClose={() => history.push('/admin/dealers')}
      />
    )
  )
}

export default DeleteUserView
