import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { Colors, Headings } from '@flow/style'
import { Icons } from '@flow/icons'
import { CommentBoxProps } from '../../types/common/common'

const Container = styled.div`
  background-color: ${Colors.FerrariLightest};
  padding: 20px;
`

const TitleButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  outline: none;
  border: none;
  cursor: pointer;
  background: transparent;
  padding: 0;
`

const Comment = styled.div`
  padding-top: 20px;
  word-break: break-word;
`

const CommentBox = ({
  title,
  comment,
  isOpen,
  toggleOpen,
  t
}: CommentBoxProps) => (
  <Container>
    <TitleButton
      type="button"
      onClick={() => {
        toggleOpen(!isOpen)
      }}
    >
      <Headings.H4>{t(title)}</Headings.H4>
      {isOpen ? (
        <Icons.ChevronUp color={Colors.Sea} />
      ) : (
        <Icons.ChevronDown color={Colors.Sea} />
      )}
    </TitleButton>
    {isOpen && <Comment>{comment}</Comment>}
  </Container>
)

CommentBox.defaultProps = {
  title: 'Comment',
  isOpen: false,
  toggleOpen: () => {}
}

export default withTranslation('inbox')(CommentBox)
