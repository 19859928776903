import { APPS } from '@flow/case-manager'

import inbox from './inbox.json'
import inboxEn from './inboxEn.json'
import documentNamesNo from './documentNamesNo.json'
import documentNamesEn from './documentNamesEn.json'
import process from './process.json'
import processEn from './processEn.json'
import businessStatusesNo from './businessStatusesNo.json'
import businessStatusesEn from './businessStatusesEn.json'
import searchEn from './searchEn.json'
import searchNo from './searchNo.json'

export const customTranslations = [
  {
    app: APPS.Inbox,
    language: 'no',
    translates: {
      ...inbox,
      ...documentNamesNo,
      ...businessStatusesNo
    }
  },
  {
    app: APPS.Inbox,
    language: 'en',
    translates: {
      ...inboxEn,
      ...documentNamesEn,
      ...businessStatusesEn
    }
  },
  {
    app: APPS.Search,
    language: 'no',
    translates: {
      ...inbox,
      ...documentNamesNo,
      ...businessStatusesNo,
      ...searchNo
    }
  },
  {
    app: APPS.Search,
    language: 'en',
    translates: {
      ...inboxEn,
      ...documentNamesEn,
      ...businessStatusesEn,
      ...searchEn
    }
  },
  {
    app: APPS.Process,
    language: 'no',
    translates: process
  },
  {
    app: APPS.Process,
    language: 'en',
    translates: processEn
  }
]

export default {
  customTranslations
}
