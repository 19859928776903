import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { SecondaryButton } from '@flow/buttons'
import { FontStyles, Colors } from '@flow/style'
import { Icons } from '@flow/icons'
import { UploadMulitpleFormProps } from '../../types/common/common'

const UploadButton = styled(SecondaryButton)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 130px;

  svg {
    padding-right: 10px;
  }
`

const UploadFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 15px;

  /*Hide the real input*/
  input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
`

const FileTitle = styled.div`
  padding-bottom: 10px;
  ${FontStyles.Large};
  display: flex;
`

const RemoveButton = styled.button`
  outline: none;
  border: none;
  cursor: pointer;
`

const UploadMulitpleForm = ({
  files,
  onFileAdded,
  onFileRemoved,
  t
}: UploadMulitpleFormProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [pastedFileNum, setPastedFileNum] = useState(1)
  let fileInput = useRef<HTMLInputElement | null>(null)

  // this.onChange = this.onChange.bind(this);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onFileAdded(e.target.files)
  }

  function handleFileSelectionClick(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault()
    fileInput.current?.click()
  }

  function handleRemoveClick(
    e: React.MouseEvent<HTMLElement>,
    fileName: string
  ) {
    e.preventDefault()
    onFileRemoved(fileName)
  }

  function handlePasteEvent(e: React.ClipboardEvent) {
    const { items } = e.clipboardData
    let file = null
    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf('image') === 0) {
        file = items[i].getAsFile()
      }
    }

    if (file) {
      const blob = file.slice(0, file.size, 'image/png')
      const fileName = `${t('Pasted file')}${pastedFileNum}.png`
      const newFile = new File([blob], fileName, { type: 'image/png' })
      setPastedFileNum((prevState) => prevState + 1)
      onFileAdded([newFile])
    }
  }

  return (
    <UploadFormContainer onPaste={(e) => handlePasteEvent(e)}>
      {files.map((file) => (
        <FileTitle key={file.name}>
          <p>{file.name}</p>
          <RemoveButton
            disabled={isSubmitting}
            type="button"
            title={t('Remove file')}
            onClick={(e) => handleRemoveClick(e, file.name)}
          >
            <Icons.Remove color={Colors.Ferrari} />
          </RemoveButton>
        </FileTitle>
      ))}
      {!isSubmitting && (
        <form>
          <input
            type="file"
            name="file"
            id="file"
            multiple
            ref={(input) => {
              fileInput.current = input
            }}
            onChange={onChange}
          />
          <UploadButton
            size="large"
            onClick={(e: React.MouseEvent<HTMLElement>) =>
              handleFileSelectionClick(e)
            }
          >
            <Icons.Upload color={Colors.Sea} />
            {t('Select a file')}
          </UploadButton>
        </form>
      )}
    </UploadFormContainer>
  )
}

UploadMulitpleForm.defaultProps = {
  files: []
}

export default withTranslation('inbox')(UploadMulitpleForm)
