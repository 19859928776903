import { parse, differenceInYears } from 'date-fns'
import { FamilyIcon } from '../../icons/FamilyIcon'
import AffordabilityAnswers from '../../types/AffordabilityAnswers'

export type FamilyCardProps = {
  affordabilityAnswers: AffordabilityAnswers
  customer: any
}

export function FamilyCard({
  affordabilityAnswers,
  customer
}: FamilyCardProps) {
  return (
    <div className="row-span-1">
      <div className="rounded-lg mb-6 border border-gray-300 overflow-hidden">
        <div className="bg-caution-100 text-caution-800 p-4 border-b border-gray-300 flex gap-3">
          <FamilyIcon />
          <h2 className="text-md font-medium">Familie</h2>
        </div>
        <div className="text-sm divide-y divide-gray-200 p-4">
          <div className="py-3 flex justify-between">
            <span>{customer?.name}</span>
            <span>{getAgeBySsn(customer?.id)} år</span>
          </div>
          {affordabilityAnswers['children'] ? (
            <>
              {affordabilityAnswers['childrenAge']?.map((age: string) => {
                return (
                  <div className="py-3 flex justify-between">
                    <span>Barn</span>
                    <span>
                      {age}
                      {typeof age === 'string' && age.includes('år')
                        ? ''
                        : ' år'}
                    </span>
                  </div>
                )
              })}
            </>
          ) : (
            <div className="py-3 flex justify-between">
              <span>Ingen barn</span>
            </div>
          )}
          <div className="py-3 flex justify-between">
            <span className="pr-3">Deler du kostnader på barna?</span>
            <span>
              {affordabilityAnswers['sharedCustody'] === 'yes' ? 'Ja' : 'Nei'}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

function getAgeBySsn(ssn: string) {
  const birthDate = parse(ssn.substring(0, 6), 'ddMMyy', new Date())
  const age = differenceInYears(new Date(), birthDate)
  return Math.abs(age)
}
