import { TaskProps } from '../../types/TaskProps'
import { IncomeCard } from './IncomeCard'
import { LivingSituationCard } from './LivingSituationCard'
import { FamilyCard } from './FamilyCard'
import { DebtCard } from './DebtCard'

interface AffordabilityAnswersProps {
  flow: TaskProps['flow']
  t: TaskProps['t']
}

export default function AffordabilityAnswers({
  flow,
  t
}: AffordabilityAnswersProps) {
  const { affordabilityAnswers, customer } = flow?.data

  if (!affordabilityAnswers) {
    return <EmptyState />
  }

  return (
    <div className="container mx-auto">
      <div className="grid grid-cols-2 gap-x-5">
        <IncomeCard affordabilityAnswers={affordabilityAnswers} />

        <LivingSituationCard affordabilityAnswers={affordabilityAnswers} />

        <DebtCard affordabilityAnswers={affordabilityAnswers} />

        <FamilyCard
          affordabilityAnswers={affordabilityAnswers}
          customer={customer}
        />
      </div>
    </div>
  )
}

function EmptyState() {
  return (
    <div className="flex flex-col items-center justify-center h-full">
      <div className="text-2xl font-semibold text-gray-700">
        <span>Kredittspørsmål er ikke besvart av kunde</span>
      </div>
    </div>
  )
}
