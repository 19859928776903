import styled, { css } from 'styled-components'
import { Colors, FontStyles } from '@flow/style'

export const ScreenContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
`

export const LeftContent = styled.div`
  display: flex;
  align-items: flex-start;
  overflow: hidden scroll;
  overflow-x: auto;
  width: 100%;
`

export const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 30%;
`

const borderStyle = `1px solid ${Colors.Grey3}`
const groupPadding = '20px'

export const Group = styled.div`
  :not(:last-of-type) {
    border-bottom: ${borderStyle};
  }
`

export const GroupPadded = styled(Group)`
  padding: ${groupPadding};
`

interface GroupPaddedProps {
  direction?: 'up' | 'down'
}

export const GroupPaddedGrow = styled(GroupPadded)<GroupPaddedProps>`
  flex-grow: 2;
  overflow: auto;

  ${(props) =>
    props.direction === 'up'
      ? css`
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
        `
      : ''}
`

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: ${groupPadding};
`

export const Error = styled.p`
  ${FontStyles.Small};
  color: ${Colors.Ferrari};
  margin: 5px 0 15px 0;
  text-align: center;
`
