import ReactDOM from 'react-dom'

import { CaseManager, APPS, FEATURES } from '@flow/case-manager'
import { Icons } from '@flow/icons'
import { Colors } from '@flow/style'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import * as serviceWorker from './serviceWorker'
import * as customerSetup from './customer'
import AuthenticationIcon from './customer/plugins/AuthenticationIcon'
import AdminApp from './customer/components/admin-dealers/App'
import { customTranslations } from './customer/translates'
import './index.css'
import businessStatusSe from './customer/utils/selectBusinessStatusSe'
import businessStatusNo from './customer/utils/selectBusinessStatusNo'

const skin = {
  branding: customerSetup.Branding,
  mainLogo: customerSetup.MainLogo
}

const { featureToggles = [] } = window.stacc_env
const kycEnabled = featureToggles.includes('kyc')
const kycSeEnabled = featureToggles.includes('kyc-se')
const kycBusinessEnabled = featureToggles.includes('kyc-business')
const kycBusinessSeEnabled = featureToggles.includes('kyc-business-se')
const englishEnabled = featureToggles.includes('english')
const isSEPortal = featureToggles.includes('se-portal')

const language = englishEnabled ? 'en' : 'no'
const isSwedenPortal = featureToggles.includes('se-portal')
const businessStatus = isSwedenPortal ? businessStatusSe : businessStatusNo
const queryClient = new QueryClient()

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <CaseManager
      configuration={() => ({
        apps: [APPS.Inbox, APPS.Search, APPS.Process],
        skin,
        language,
        homeUrl: '/inbox/my-cases',
        customComponents: customerSetup.CustomComponents,
        inboxConfiguration: {
          searchFields: customerSetup.SearchFields,
          queuesPollingEnabled: false,
          queueSortingEnabled: false,
          features: [
            FEATURES.CaseNotes,
            FEATURES.CaseChecklist,
            FEATURES.Chat,
            FEATURES.CaseAssignment
          ],
          startableProcesses: [
            ...(kycEnabled
              ? ['source-of-funds-private', 'customer-declaration-private']
              : []),
            ...(kycBusinessEnabled
              ? ['source-of-funds-business', 'customer-declaration-business']
              : []),
            ...(kycSeEnabled ? ['customer-declaration-private-se'] : []),
            ...(kycBusinessSeEnabled
              ? ['customer-declaration-business-se']
              : []),
            // TODO: Remove
            ...(isSwedenPortal ? ['document-control-se'] : [])
          ]
        },
        searchConfiguration: {
          searchProcesses: [
            'document-control',
            'document-control-se',
            'credit-check',
            'payout-check',
            ...(kycEnabled
              ? ['customer-declaration-private', 'source-of-funds-private']
              : []),
            ...(kycBusinessEnabled
              ? ['source-of-funds-business', 'customer-declaration-business']
              : []),
            ...(kycSeEnabled ? ['customer-declaration-private-se'] : []),
            ...(kycBusinessSeEnabled
              ? ['customer-declaration-business-se']
              : []),
            'cleanup-process',
            'daily-chat-reports'
          ],
          searchQueryView: 'searchView',
          searchFields: [
            {
              name: 'search-customer-name',
              value: 'data.customer.name',
              queryOperator: 'contains'
            },
            {
              name: 'search-customer-id',
              value: 'data.customer.id'
            },
            {
              name: 'search-vendor',
              value: 'data.vendor.name',
              process: [
                'document-control',
                'document-control-se',
                'credit-check',
                'ALL'
              ],
              queryOperator: 'contains'
            },
            {
              name: 'search-salesperson-name',
              value: 'data.salesPerson.name',
              process: [
                'document-control',
                'document-control-se',
                'credit-check',
                'ALL'
              ],
              queryOperator: 'contains'
            },
            {
              name: 'search-reg-no',
              value: 'data.asset.registrationNumber',
              process: [
                'document-control',
                'document-control-se',
                'credit-check',
                'ALL'
              ],
              queryOperator: 'contains'
            },
            {
              name: 'search-status',
              value: 'data.businessStatus',
              type: 'select',
              options: businessStatus
            }
          ],
          searchResultFields: [
            { name: 'search-customer', value: 'data.customer.name' },
            {
              name: 'search-co-customer',
              value: 'data.coCustomer.name',
              process: ['customer-declaration-private', 'ALL']
            },
            {
              name: 'search-vendor',
              value: 'data.vendor.name',
              process: [
                'document-control',
                'document-control-se',
                'credit-check',
                'ALL'
              ]
            },
            {
              name: 'search-salesperson',
              value: 'data.salesPerson.name',
              process: [
                'document-control',
                'document-control-se',
                'credit-check',
                'ALL'
              ]
            },
            {
              name: 'search-reg-no',
              value: 'data.asset.registrationNumber',
              process: [
                'document-control',
                'document-control-se',
                'credit-check',
                'ALL'
              ]
            },
            {
              name: 'search-e-sign',
              value: 'data.caseDetails.signOption',
              process: [
                'document-control',
                'document-control-se',
                'credit-check',
                'ALL'
              ],
              mapping: (value) => (value === 1 ? 'No' : 'Yes')
            },
            {
              name: 'search-inbox-date',
              value: 'data.inboxDate',
              process: ['document-control', 'document-control-se', 'ALL']
            },
            {
              name: 'Status',
              value: 'data.businessStatus',
              mapping: (value) => `business-status-${value}`,
              process: [
                'customer-declaration-private',
                'customer-declaration-business',
                'customer-declaration-business-se',
                'source-of-funds-private',
                'source-of-funds-business',
                'document-control-se',
                'document-control',
                'ALL'
              ]
            }
          ],
          features: [
            FEATURES.CaseNotes,
            FEATURES.CaseChecklist,
            FEATURES.Chat,
            FEATURES.CaseAssignment
          ]
        },
        caseChecklistConfiguration: {
          enabled: (flow) => {
            if (
              flow &&
              (flow.flowDefinitionId === 'customer-declaration-private' ||
                flow.flowDefinitionId === 'customer-declaration-business' ||
                flow.flowDefinitionId === 'customer-declaration-business-se' ||
                flow.flowDefinitionId === 'source-of-funds-private' ||
                flow.flowDefinitionId === 'source-of-funds-business' ||
                flow.flowDefinitionId === 'document-control-se' ||
                (flow.flowDefinitionId === 'credit-check' && isSEPortal))
            ) {
              return false
            }
            return true
          }
        },
        caseAssignmentConfiguration: {
          caseownersTeamId: (flow) => {
            if (!flow) {
              return 'CASEWORKERS'
            }
            if (flow && flow.flowDefinitionId === 'credit-check') {
              return 'CREDIT'
            }
            return 'DOCUMENT_CONTROL'
          }
        },
        chatConfiguration: {
          rooms: (flow) => {
            if (!flow) {
              return [
                { title: 'Vendor', suffix: 'salesperson-caseworker' },
                { title: 'Customer', suffix: 'customer-caseworker' }
              ]
            }
            if (
              flow.flowDefinitionId === 'document-control' ||
              flow.flowDefinitionId === 'document-control-se' ||
              flow.flowDefinitionId === 'credit-check'
            ) {
              return [
                { title: 'Vendor', suffix: 'salesperson-caseworker' },
                { title: 'Customer', suffix: 'customer-caseworker' }
              ]
            }
            if (flow.flowDefinitionId === 'source-of-funds-private') {
              return [{ title: 'Customer', suffix: 'customer-caseworker' }]
            }
            if (flow.flowDefinitionId === 'source-of-funds-business') {
              if (flow.data.isFlowManagerApplication) {
                return [{ title: 'Customer', suffix: 'customer-caseworker' }]
              }
            }
            return []
          }
        },
        loginConfiguration: {
          authenticationIcon: AuthenticationIcon
        },
        translates: customTranslations,
        customRoutes: [
          {
            path: '/admin',
            component: AdminApp,
            navigation: {
              title: 'Admin',
              icon: () => <Icons.AccessControl color={Colors.Snow} />,
              startLink: '/admin/dealers'
            }
          }
        ]
      })}
    />
  </QueryClientProvider>,
  document.getElementById('root')
)
serviceWorker.unregister()
