import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'
import * as flowStyle from '@flow/style'
import { StakeholderProps } from '../../types/adminDealers'

const LabeledPart = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
`

const IdentityPart = styled(LabeledPart)`
  ${flowStyle.FontStyles.Large}
  color: initial;
`

const LabelTitle = styled.div`
  ${flowStyle.FontStyles.LargeStrong}
  color: initial;
`

const RolePart = styled.div`
  ${flowStyle.FontStyles.Normal}
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  color: initial;
`

const Stakeholder = ({ stakeholder }: StakeholderProps) => (
  <>
    <IdentityPart>
      <LabelTitle>{stakeholder.name}</LabelTitle>
      <div>{stakeholder.uniqname || stakeholder.username}</div>
    </IdentityPart>
    {stakeholder.access_object &&
      stakeholder.access_object.length > 0 &&
      stakeholder.access_object.map((role) => (
        <RolePart key={uuidv4()}>
          <LabeledPart>
            <LabelTitle>Role</LabelTitle>
            <div>{role.role}</div>
          </LabeledPart>
          <LabeledPart>
            <LabelTitle>Vendor</LabelTitle>
            <div>{role.vendorId}</div>
          </LabeledPart>
          <LabeledPart>
            <LabelTitle>Branch</LabelTitle>
            <div>{role.branchId}</div>
          </LabeledPart>
        </RolePart>
      ))}
  </>
)

export default Stakeholder
