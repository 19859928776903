import React, { createRef, useEffect, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { PrimaryProgressButton, SecondaryButton } from '@flow/buttons'
import { Colors } from '@flow/style'

import DocumentsMenu from './DocumentsMenu'
import DocumentsViewer from './DocumentsViewer'
import AuditForm from './AuditForm'
import Context from '../../common/task-context/Context'
import CommentBox from '../../common/CommentBox'
import Errors from './Errors'
import { validateDocument, mapTaskToDocuments } from './audithelper'
import KeyCodes from './keycodes'
import {
  ScreenContainer,
  Group,
  GroupPadded,
  GroupPaddedGrow,
  ButtonGroup
} from '../../common/TaskViewContainerStyle'
import { AuditScreenProps, AuditData } from '../../../types/audit'

const borderStyle = `1px solid ${Colors.Grey3}`

const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: ${borderStyle};
  min-width: 20%;
  max-width: 30%;
  overflow: auto;
`

const MiddleContent = styled.div`
  overflow: hidden scroll;
  overflow-x: auto;
  flex-grow: 3;
`

const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 2;
  max-width: 40%;
`

const AuditScreen = ({
  close,
  onComplete,
  onSave,
  t,
  task,
  updateCase
}: AuditScreenProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [nextIndex, setNextIndex] = useState<number>(0)
  const [auditData, setAuditData] = useState<AuditData[]>(
    mapTaskToDocuments(task)
  )
  const [displayErrors, setDisplayErrors] = useState(false)
  const submitButtonRef = createRef<HTMLButtonElement>()

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown, false)
    return () => {
      document.removeEventListener('keydown', onKeyDown, false)
    }
  }, [onkeydown, onPrevious, onNext, onFormValidationSuccess])

  useEffect(() => {
    submitButtonRef.current?.click()
  }, [nextIndex])

  const onKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'ArrowLeft') {
      onPrevious()
    }
    if (e.key === 'ArrowRight') {
      onNext()
    }
    if (e.key === 'Enter') {
      handleComplete()
    }
  }

  function onPrevious(validate = false) {
    updateIndex(currentIndex - 1, validate)
  }

  function onNext(validate = false) {
    updateIndex(currentIndex + 1, validate)
  }

  function onFormValidationSuccess() {
    setCurrentIndex(nextIndex)
  }

  function updateIndex(newIndex: number, validate = false) {
    if (newIndex < 0 || newIndex >= auditData.length) {
      return
    }

    if (validate) {
      setNextIndex(newIndex)
    } else {
      setCurrentIndex(newIndex)
    }
  }

  function updateAuditData(
    index: number,
    newData: {
      isApprovalCommentOpen?: boolean
      audit?: string
      comment?: string
    }
  ) {
    const data = { ...auditData[index], ...newData }

    const updatedAuditData = [
      ...auditData.slice(0, index),
      { ...data, valid: validateDocument(data) },
      ...auditData.slice(index + 1, auditData.length)
    ]
    setAuditData(updatedAuditData)
  }

  function handleSave() {
    setIsSubmitting(true)

    onSave(
      task.taskId,
      { documents: auditData },
      () => {
        updateCase()
        close()
        setIsSubmitting(false)
      },
      () => {
        setIsSubmitting(false)
      }
    )
  }

  function handleComplete() {
    if (auditData.find((fd: { valid: boolean }) => !fd.valid)) {
      setDisplayErrors(true)
      return
    }

    const documents = auditData.map(
      (a: { name: string; audit: string; comment: string }) => ({
        name: a.name,
        audit: a.audit,
        ...(a.comment && { comment: a.comment })
      })
    )
    setIsSubmitting(true)
    onComplete(
      task.taskId,
      { documents },
      () => {
        updateCase()
        close()
        setIsSubmitting(false)
      },
      () => {
        setIsSubmitting(false)
      }
    )
  }

  const context = task.context[currentIndex]
  const currentAudit = auditData[currentIndex]
  const fileIds = auditData.map((a: { fileId: string }) => a.fileId)

  const hasNext = auditData.length > currentIndex + 1
  const hasPrevious = currentIndex > 0

  const errorDocs = auditData.filter((fd: { valid: boolean }) => !fd.valid)
  const hasErrors = errorDocs && errorDocs.length > 0

  if (!auditData || auditData.length === 0) {
    return (
      <ScreenContainer>
        <span>No documents found</span>
        <LeftContent>
          <GroupPadded>
            <ButtonGroup>
              <PrimaryProgressButton
                title={t('Complete')}
                isLoading={isSubmitting}
                onClick={() => handleComplete()}
              >
                {t('Complete')}
              </PrimaryProgressButton>
              <SecondaryButton
                title={t('Save and close')}
                disabled={isSubmitting}
                onClick={() => handleSave()}
              >
                {t('Save and close')}
              </SecondaryButton>
            </ButtonGroup>
          </GroupPadded>
        </LeftContent>
      </ScreenContainer>
    )
  }

  return (
    <ScreenContainer>
      <LeftContent>
        <DocumentsMenu
          documents={auditData}
          displayErrors={displayErrors}
          currentIndex={currentIndex}
          onSelect={(index: number) => {
            setCurrentIndex(index)
          }}
        />
        {displayErrors && hasErrors && (
          <GroupPaddedGrow direction="up">
            <Errors documents={errorDocs} />
          </GroupPaddedGrow>
        )}
        <GroupPadded>
          <ButtonGroup>
            <PrimaryProgressButton
              title={t('Complete')}
              isLoading={isSubmitting}
              onClick={() => handleComplete()}
            >
              {t('Complete')}
            </PrimaryProgressButton>
            <SecondaryButton
              title={t('Save and close')}
              disabled={isSubmitting}
              onClick={() => handleSave()}
            >
              {t('Save and close')}
            </SecondaryButton>
          </ButtonGroup>
        </GroupPadded>
      </LeftContent>
      <MiddleContent>
        {auditData && (
          <DocumentsViewer fileIds={fileIds} currentFileId={context.fileId} />
        )}
      </MiddleContent>
      <RightContent>
        {currentAudit.approvalComment && (
          <Group>
            <CommentBox
              title="Comment from approval"
              comment={currentAudit.approvalComment}
              isOpen={currentAudit.isApprovalCommentOpen}
              toggleOpen={(isOpen: boolean) =>
                updateAuditData(currentIndex, { isApprovalCommentOpen: isOpen })
              }
            />
          </Group>
        )}
        <GroupPaddedGrow>
          <Context context={context} />
        </GroupPaddedGrow>
        <GroupPadded>
          <AuditForm
            submitButtonRef={submitButtonRef}
            formKey={currentAudit.name}
            audit={currentAudit.audit}
            comment={currentAudit.comment}
            onAuditChange={(value: string) =>
              updateAuditData(currentIndex, { audit: value })
            }
            onCommentChange={(value: string) =>
              updateAuditData(currentIndex, { comment: value })
            }
            onValidationSuccess={() => onFormValidationSuccess()}
          />
          <ButtonGroup>
            <SecondaryButton
              title={t('Previous')}
              disabled={!hasPrevious || isSubmitting}
              onClick={() => onPrevious(true)}
            >
              {t('Previous')}
            </SecondaryButton>
            <SecondaryButton
              title={t('Next')}
              disabled={!hasNext || isSubmitting}
              onClick={() => onNext(true)}
            >
              {t('Next')}
            </SecondaryButton>
          </ButtonGroup>
        </GroupPadded>
      </RightContent>
    </ScreenContainer>
  )
}

AuditScreen.defaultProps = {
  onComplete: () => {},
  onSave: () => {},
  close: () => {},
  updateCase: () => {}
}

export default withTranslation('inbox')(AuditScreen)
