import { useFlowContext } from './useFlowContext'
import { Card, Header, Content } from './Card'
import { ClipboardIcon } from '../../icons/Clipboard'
import { FileRow } from './FileRow'

type Color = 'blue' | 'green' | 'yellow' | 'purple'

function returnColors(color: Color) {
  switch (color) {
    case 'blue':
      return 'bg-primary-100 text-primary-800'
    case 'green':
      return 'bg-grass-90 text-grass-20'
    case 'yellow':
      return 'bg-caution-100 text-caution-800'
    case 'purple':
      return 'bg-purple-100 text-purple-800'
    default:
      return ''
  }
}

export function DocumentsCard({
  title,
  data,
  color
}: {
  title: string
  data: any[]
  color: Color
}) {
  const { t } = useFlowContext()
  return (
    <Card>
      <Header className={`${returnColors(color)} justify-between`}>
        <div className="flex gap-3 items-center">
          <ClipboardIcon width={18} height={18} />
          <span>{title}</span>
        </div>
        <span>{data.length}</span>
      </Header>
      <Content horizontalPadding={false}>
        {data?.length > 0 ? (
          <table className="w-full text-basic-ink">
            <thead>
              <tr className="w-full">
                <th className="text-xs text-left pl-5 pb-2">
                  {t('type').toUpperCase()}
                </th>
                <th className="text-xs text-left pb-2">
                  {t('filename').toUpperCase()}
                </th>
                <th className="text-xs text-right pb-2">
                  {t('uploaded').toUpperCase()}
                </th>
              </tr>
            </thead>
            <tbody>
              {(data || []).map((row, index) => (
                <FileRow key={index} data={row} />
              ))}
            </tbody>
          </table>
        ) : (
          <EmptyState />
        )}
      </Content>
    </Card>
  )
}

function EmptyState() {
  const { t } = useFlowContext()
  return (
    <div className="flex flex-col gap-5 text-center text-black font-bold pt-4">
      <div className="flex justify-center">
        <ClipboardIcon />
      </div>
      <span>{t('noDocuments')}</span>
    </div>
  )
}
