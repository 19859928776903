import { useEffect, useState } from 'react'
import { Formik, Form, FieldArray } from 'formik'

import * as Yup from 'yup'
import { Select, Text } from '@flow/forms'
import { Colors, Headings } from '@flow/style'
import { Icons } from '@flow/icons'
import styled from 'styled-components'

import { v4 as uuidv4 } from 'uuid'

const FormGroup = styled.div`
  margin-bottom: 2em;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  > * {
    margin-right: 1em;
  }
`

const ModalForm = styled(Form)`
  padding: 2em;
  padding-bottom: 0;
`

const AddButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
`

const RemoveButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
`

const StakeholderSchema = Yup.object().shape({
  nid: Yup.string()
    .required('Required')
    .matches(/^[0-9]+$/, 'Digits only'),
  name: Yup.string().required('Required'),
  role: Yup.array().of(
    Yup.object()
      .shape({
        role: Yup.string().oneOf([
          'generalmanager',
          'salesmanager',
          'secretary',
          'salesperson'
        ]),
        branchId: Yup.string().matches(/^[0-9]+$/, 'Digits only'),
        vendorId: Yup.string().matches(/^[0-9]+$/, 'Digits only')
      })
      .required('Role issue')
  )
})

type StakeholderFormProps = {
  initialValues: {
    nid: string
    name: string
    roles: { vendorId: string; branchId: string; role: string }[]
  }
  onSubmit: any
  children: any
}
// TODO: Inherit propTypes from Formik
const StakeholderForm = ({
  initialValues,
  onSubmit,
  children
}: StakeholderFormProps) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={StakeholderSchema}
      onSubmit={onSubmit}
    >
      {(formikBag) => {
        const {
          values,
          handleChange,
          handleBlur,
          isSubmitting,
          errors,
          touched
        } = formikBag

        return (
          <ModalForm>
            <FormGroup>
              <Text
                name="nid"
                label="SSN"
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isSubmitting}
                defaultValue={values.nid}
              />
              {errors.nid && touched.nid ? <div>{errors.nid}</div> : null}
              <Text
                name="name"
                label="Full name"
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isSubmitting}
                defaultValue={values.name}
              />
              {errors.name && touched.name ? <div>{errors.name}</div> : null}
            </FormGroup>
            <Headings.H2>Roles</Headings.H2>
            <FieldArray name="roles">
              {({ push, remove }) => (
                <div>
                  {values.roles &&
                    values.roles.length > 0 &&
                    values.roles.map((role, index) => (
                      <FormGroup key={'branchid' + index}>
                        <Text
                          name={`roles.${index}.vendorId`}
                          label="Vendor ID"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={isSubmitting}
                          defaultValue={role.vendorId}
                        />
                        <Select
                          name={`roles.${index}.role`}
                          id={`roles.${index}.role`}
                          label="Type of role"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={isSubmitting}
                          value={role.role}
                          options={[
                            { label: 'salesperson', value: 'salesperson' },
                            {
                              label: 'generalmanager',
                              value: 'generalmanager'
                            },
                            { label: 'salesmanager', value: 'salesmanager' },
                            { label: 'secretary', value: 'secretary' }
                          ]}
                        />
                        <Text
                          name={`roles.${index}.branchId`}
                          label="Branch ID"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={isSubmitting}
                          defaultValue={role.branchId}
                        />
                        <RemoveButton
                          type="button"
                          onClick={() => remove(index)}
                        >
                          <Icons.Remove color={Colors.Ferrari} />
                        </RemoveButton>
                      </FormGroup>
                    ))}
                  <AddButton
                    type="button"
                    onClick={() =>
                      push({ vendorId: '', branchId: '', role: 'salesperson' })
                    }
                  >
                    <Icons.Add />
                    {' Add new role'}
                  </AddButton>
                </div>
              )}
            </FieldArray>
            {children(formikBag)}
          </ModalForm>
        )
      }}
    </Formik>
  )
}

export default StakeholderForm
