import axios from 'axios'

export const getStakeholder = async (userId) =>
  axios.get(`/api/stakeholder/stakeholders/${userId}`)

export const getStakeholders = async (filters) =>
  axios.get('/api/stakeholder/stakeholders', { params: filters })

export const postStakeholder = async (stakeholder) =>
  axios.post('/api/stakeholder/stakeholders', stakeholder)

export const createStakeholder = async (values) =>
  postStakeholder({
    uniqname: values.nid,
    username: values.nid,
    name: values.name,
    type: 'Person',
    access_object: (values.roles || []).map((role) => ({
      branchId: role.branchId,
      vendorId: role.vendorId,
      role: role.role
    }))
  })

export const putStakeholder = async (userId, stakeholder) =>
  axios.put(`/api/stakeholder/stakeholders/${userId}`, stakeholder)

export const editStakeholder = async ({ userId, values }) =>
  putStakeholder(userId, {
    uniqname: values.nid,
    username: values.nid,
    name: values.name,
    type: 'Person',
    access_object: (values.roles || []).map((role) => ({
      branchId: role.branchId,
      vendorId: role.vendorId,
      role: role.role
    }))
  })

export const deleteStakeholder = async (userId) =>
  axios.delete(`/api/stakeholder/stakeholders/${userId}`)
