import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'

import { Colors, Headings } from '@flow/style'
import { ErrorsProps } from '../../../types/audit'

const ErrorsContainer = styled.div`
  h6 {
    color: ${Colors.Ferrari};
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
`

const Errors = ({ documents, t }: ErrorsProps) => (
  <ErrorsContainer>
    <Headings.H6>{t('Missing decision or reasons')}</Headings.H6>
    <ul>
      {documents.map((d) => (
        <li key={d.name}>{t(d.name)}</li>
      ))}
    </ul>
  </ErrorsContainer>
)

export default withTranslation('inbox')(Errors)
