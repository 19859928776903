const debounce = (fn, interval) => {
  let timeout
  return (...rest) => {
    const func = () => {
      fn.apply(this, rest)
    }
    if (timeout) {
      clearTimeout(timeout)
    }
    timeout = setTimeout(func, interval)
  }
}

export default debounce
