import { useEffect, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'

import { PrimaryProgressButton, SecondaryButton } from '@flow/buttons'
import { Headings } from '@flow/style'
import { Text } from '@flow/forms'

import Checkbox from '../../common/Checkbox'
import {
  ScreenContainer,
  LeftContent,
  RightContent,
  GroupPadded,
  GroupPaddedGrow,
  ButtonGroup
} from '../../common/TaskViewContainerStyle'
import {
  PostControl,
  SelectPostControlScreenProps
} from '../../../types/postControl'

const List = styled.div`
  margin-top: 10px;
`

const AddToListForm = styled.form`
  display: flex;
  align-items: center;
  padding-top: 20px;

  input {
    margin-right: 10px;
    width: 300px;
  }
`

const CheckboxWrapper = styled.div`
  margin-bottom: 10px;
`

const postControlListNo = [
  { name: 'switchLegitimasjon', value: false },
  { name: 'switchKausjonsavtale', value: false },
  { name: 'switchPant', value: false }
]
const postControlListSe = [
  { name: 'Agreement', value: false },
  { name: 'Acceptance of delivery', value: false },
  { name: 'Guarantor', value: false },
  { name: 'RV/Buy Back', value: false },
  { name: 'Milage', value: false }
]

const SelectPostControlScreen = ({
  close,
  flow,
  onComplete,
  onSave,
  t,
  task,
  updateCase
}: SelectPostControlScreenProps) => {
  const controlList =
    flow.data.caseDetails.countryId === 'SE'
      ? postControlListSe
      : postControlListNo
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [postControlList, setPostControlList] =
    useState<PostControl[]>(controlList)
  const [newCheckItem, setNewCheckItem] = useState('')

  useEffect(() => {
    if (task.data && task.data.postControlList) {
      setPostControlList(task.data.postControlList)
    }
  }, [])

  const onSelect = (field: string, newValue: boolean) => {
    const duplicatePostControlList = postControlList.map((el) => {
      if (el.name === field) {
        return { ...el, value: newValue }
      }
      return el
    })
    setPostControlList(duplicatePostControlList)
  }

  const handleSave = () => {
    setIsSubmitting(true)
    onSave(
      task.taskId,
      { postControlList },
      () => {
        updateCase()
        close()
        setIsSubmitting(false)
      },
      () => {
        setIsSubmitting(false)
      }
    )
  }

  const handleComplete = () => {
    const finalList = postControlList.filter(
      (el: PostControl) => el.value === true
    )

    setIsSubmitting(true)
    onComplete(
      task.taskId,
      { postControlList: finalList },
      () => {
        updateCase()
        close()
        setIsSubmitting(false)
      },
      () => {
        setIsSubmitting(false)
      }
    )
  }

  const addToList = (e: React.FormEvent) => {
    e.preventDefault()
    const newValue = newCheckItem

    if (newValue) {
      setPostControlList([...postControlList, { name: newValue, value: true }])
    }
  }

  const controlListComponent = postControlList.map((el: PostControl) => (
    <CheckboxWrapper key={uuidv4()}>
      <Checkbox
        id={el.name}
        name={el.name}
        label={t(el.name)}
        value={el.value + ''}
        checked={el.value}
        onChange={(e) => onSelect(el.name, e.target.checked)}
      />
    </CheckboxWrapper>
  ))

  return (
    <ScreenContainer>
      <LeftContent>
        <GroupPadded>
          <GroupPadded>
            <Headings.H6>{t('Choose items for checklist')}</Headings.H6>
            <List>{controlListComponent}</List>
            <AddToListForm onSubmit={(e: React.FormEvent) => addToList(e)}>
              <Text
                id="newCheckItem"
                name="newCheckItem"
                onChange={(e: React.ChangeEvent, value: string) =>
                  setNewCheckItem(value)
                }
              />
              <SecondaryButton type="submit">{t('Add')}</SecondaryButton>
            </AddToListForm>
          </GroupPadded>
        </GroupPadded>
      </LeftContent>
      <RightContent>
        <GroupPaddedGrow />
        <GroupPadded>
          <ButtonGroup>
            <SecondaryButton
              onClick={() => handleSave()}
              disabled={isSubmitting}
            >
              {t('Save and close')}
            </SecondaryButton>
            <PrimaryProgressButton
              type="submit"
              onClick={() => handleComplete()}
              disabled={isSubmitting}
              isLoading={isSubmitting}
            >
              {t('Complete')}
            </PrimaryProgressButton>
          </ButtonGroup>
        </GroupPadded>
      </RightContent>
    </ScreenContainer>
  )
}

SelectPostControlScreen.defaultProps = {
  onComplete: () => {},
  onSave: () => {},
  close: () => {},
  updateCase: () => {}
}

export default withTranslation('inbox')(SelectPostControlScreen)
