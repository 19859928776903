const queueConfigurations = [
  //* Credit process
  {
    name: '(Credit) Inbox',
    conditions: (flow: any) =>
      !['completed', 'archived'].includes(flow.status) &&
      flow.flowDefinitionId === 'credit-check' &&
      !flow.assignee &&
      flow.data?.caseDetails?.contractStatus !== 'Approved'
  },
  {
    name: '(Credit) Ongoing',
    conditions: (flow: any) =>
      !['completed', 'archived'].includes(flow.status) &&
      flow.flowDefinitionId === 'credit-check' &&
      flow.assignee &&
      flow.data?.caseDetails?.contractStatus !== 'Approved'
  },
  {
    name: '(Credit) Approved',
    conditions: (flow: any) =>
      !['completed', 'archived'].includes(flow.status) &&
      flow.flowDefinitionId === 'credit-check' &&
      flow.data?.caseDetails?.contractStatus === 'Approved'
  },
  {
    name: '(Credit) Failed',
    conditions: (flow: any, tasks: any) =>
      !['archived'].includes(flow.status) &&
      flow.flowDefinitionId === 'credit-check' &&
      tasks.some((task: any) => task.status === 'failed')
  },
  {
    name: '(Credit) Unapproved uploads',
    conditions: (flow: any, tasks: any) =>
      flow.status === 'active' &&
      flow.flowDefinitionId === 'credit-check' &&
      tasks.some(
        (task: any) =>
          task.taskType === 'cc-approve-document' && task.status === 'pending'
      )
  },
  //* Document control
  {
    name: 'Incoming',
    conditions: (flow: any) =>
      !['completed', 'archived'].includes(flow.status) &&
      flow.flowDefinitionId === 'document-control-se' &&
      !flow.data?.externalRequiredDocumentsOk
  },
  {
    name: 'Inbox',
    conditions: (flow: any) =>
      !['completed', 'archived'].includes(flow.status) &&
      flow.flowDefinitionId === 'document-control-se' &&
      flow.data?.externalRequiredDocumentsOk &&
      !flow.data?.allDocumentsAreOk &&
      !flow.assignee
  },
  {
    name: 'Control',
    conditions: (flow: any) =>
      !['completed', 'archived'].includes(flow.status) &&
      flow.flowDefinitionId === 'document-control-se' &&
      flow.data?.externalRequiredDocumentsOk &&
      !flow.data?.allDocumentsAreOk &&
      flow.assignee
  },
  {
    name: 'Audit',
    conditions: (flow: any, tasks: any) =>
      !['completed', 'archived'].includes(flow.status) &&
      flow.flowDefinitionId === 'document-control-se' &&
      tasks.some(
        (task: any) =>
          task.taskType === 'dcse-audit-documents' && task.status === 'pending'
      )
  },
  {
    name: 'Select post control',
    conditions: (flow: any, tasks: any) =>
      !['completed', 'archived'].includes(flow.status) &&
      flow.flowDefinitionId === 'document-control-se' &&
      tasks.some(
        (task: any) =>
          task.taskType === 'dcse-decide-followup-needed' &&
          task.status === 'pending'
      )
  },
  {
    name: 'Post control',
    conditions: (flow: any, tasks: any) =>
      !['completed', 'archived'].includes(flow.status) &&
      flow.flowDefinitionId === 'document-control-se' &&
      tasks.some(
        (task: any) =>
          task.taskType === 'dcse-follow-up' && task.status === 'pending'
      )
  },
  {
    name: 'Archiving',
    conditions: (flow: any) =>
      !['completed', 'archived'].includes(flow.status) &&
      flow.flowDefinitionId === 'document-control-se' &&
      flow.data?.storedFiles
  },
  {
    name: 'Failed',
    conditions: (flow: any, tasks: any) =>
      !['archived'].includes(flow.status) &&
      flow.flowDefinitionId === 'document-control-se' &&
      tasks.some((task: any) => task.status === 'failed')
  },
  //* Payout
  {
    name: 'Register contract (HP)',
    taskId: 'dcse-contract-and-car-registry-check',
    conditions: (flow: any, tasks: any) =>
      !['archived'].includes(flow.status) &&
      flow.flowDefinitionId === 'document-control-se' &&
      flow.data?.product?.ncvsId === 545 &&
      tasks.some(
        (task: any) =>
          task.taskType === 'dcse-contract-and-car-registry-check' &&
          task.status === 'pending'
      )
  },
  {
    name: 'Perform payout (HP)',
    taskId: 'dcse-perform-payout-and-start-contract',
    conditions: (flow: any, tasks: any) =>
      !['archived'].includes(flow.status) &&
      flow.flowDefinitionId === 'document-control-se' &&
      flow.data?.product?.ncvsId === 545 &&
      tasks.some(
        (task: any) =>
          task.taskType === 'dcse-perform-payout-and-start-contract' &&
          task.status === 'pending'
      ) &&
      !flow.assignee
  },
  {
    name: 'Register contract (LE)',
    taskId: 'dcse-contract-and-car-registry-check',
    conditions: (flow: any, tasks: any) =>
      !['archived'].includes(flow.status) &&
      flow.flowDefinitionId === 'document-control-se' &&
      flow.data.product.ncvsId !== 545 &&
      tasks.some(
        (task: any) =>
          task.taskType === 'dcse-contract-and-car-registry-check' &&
          task.status === 'pending'
      )
  },
  {
    name: 'Perform payout (LE)',
    taskId: 'dcse-perform-payout-and-start-contract',
    conditions: (flow: any, tasks: any) =>
      !['archived'].includes(flow.status) &&
      flow.flowDefinitionId === 'document-control-se' &&
      flow.data?.product?.ncvsId !== 545 &&
      tasks.some(
        (task: any) =>
          task.taskType === 'dcse-perform-payout-and-start-contract' &&
          task.status === 'pending'
      ) &&
      !flow.assignee
  },
  //* Customer control - car
  {
    name: 'Customer declaration - answered',
    conditions: (flow: any) =>
      !['completed', 'archived'].includes(flow.status) &&
      [
        'customer-declaration-private-se',
        'customer-declaration-business-se'
      ].includes(flow.flowDefinitionId) &&
      flow.data?.businessStatus === 'assessment' &&
      flow.data?.concept === 'car' &&
      !flow.assignee
  },
  {
    name: 'Customer declaration - control',
    conditions: (flow: any) =>
      !['completed', 'archived'].includes(flow.status) &&
      [
        'customer-declaration-private-se',
        'customer-declaration-business-se'
      ].includes(flow.flowDefinitionId) &&
      flow.data?.businessStatus === 'assessment' &&
      flow.data?.concept === 'car' &&
      flow.assignee
  },
  {
    name: 'Customer control (Failed)',
    conditions: (flow: any, tasks: any) =>
      !['archived'].includes(flow.status) &&
      [
        'customer-declaration-private-se',
        'customer-declaration-business-se',
        'source-of-funds-private',
        'source-of-funds-business'
      ].includes(flow.flowDefinitionId) &&
      flow.data?.concept === 'car' &&
      tasks.some(
        (task: any) =>
          task.taskType === 'dcse-assess-customer-declaration' &&
          task.status === 'failed'
      )
  }
]

function findMatchingQueues(tasks: any, flow: any) {
  if (!tasks) {
    return ['Loading..']
  }

  const queues = queueConfigurations.reduce((acc: any, queue: any) => {
    if (queue.conditions(flow, tasks)) {
      acc.push({ name: queue.name, taskId: queue.taskId })
    }
    return acc
  }, [])

  return queues
}

export default findMatchingQueues
