import { useEffect } from 'react'
import styled from 'styled-components'
import { Icons } from '@flow/icons'
import { TransparentButton } from '@flow/buttons'
import { Colors } from '@flow/style'

import debounce from '../../../helpers/debonce'
import { ZoomBarProps } from '../../../types/common/documentsViewer'

const DEFAULT_SCALE = 1
const MAX_SCALE = 5
const MIN_SCALE = 1
const SCALE_DELTA = 1.1

const Toolbar = styled.div`
  position: absolute;
  background-color: ${Colors.SeaLighter};
  z-index: 2;
`

const ZoomToolbar = ({ scale, onZoom }: ZoomBarProps) => {
  useEffect(() => {
    document.addEventListener('keydown', onKeyDown, false)

    return () => {
      document.removeEventListener('keydown', onKeyDown, false)
    }
  }, [onKeyDown, zoomIn, zoomOut])

  function onKeyDown(e: { keyCode: number }) {
    if (e.keyCode === 107 || e.keyCode === 187) {
      zoomIn()
    }
    if (e.keyCode === 109 || e.keyCode === 189) {
      zoomOut()
    }
  }

  function zoomIn() {
    let newScale = parseFloat((scale * SCALE_DELTA).toFixed(2))
    newScale = Math.ceil(newScale * 10) / 10
    newScale = Math.min(MAX_SCALE, newScale)

    onZoom(newScale)
  }

  function zoomOut() {
    let newScale = parseFloat((scale / SCALE_DELTA).toFixed(2))
    newScale = Math.floor(newScale * 10) / 10
    newScale = Math.max(MIN_SCALE, newScale)

    onZoom(newScale)
  }

  const scaleIsMin = scale === MIN_SCALE
  const scaleIsMax = scale === MAX_SCALE

  return (
    <Toolbar>
      <TransparentButton
        title="Zoom in (Ctrl +)"
        type="button"
        disabled={scaleIsMax}
        onClick={debounce(zoomIn, 100)}
      >
        <Icons.Plus color={scaleIsMax ? Colors.Grey2 : Colors.Sea} />
      </TransparentButton>
      <TransparentButton
        title="Zoom out (Ctrl -)"
        type="button"
        disabled={scaleIsMin}
        onClick={debounce(zoomOut, 100)}
      >
        <Icons.Minus color={scaleIsMin ? Colors.Grey2 : Colors.Sea} />
      </TransparentButton>
    </Toolbar>
  )
}

ZoomToolbar.defaultProps = {
  scale: DEFAULT_SCALE,
  onZoom: () => {}
}

export default ZoomToolbar
