import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import { Headings } from '@flow/style'

import CommentBox from '../../common/CommentBox'
import { DelayedApprovalContextProps } from '../../../types/postControl'

const List = styled.div`
  margin-top: 10px;
`

const Item = styled.div`
  padding-bottom: 10px;
`

const DelayedApprovalContext = ({
  delayedApprovals,
  t
}: DelayedApprovalContextProps) => {
  const [approvals, setApprovals] = useState(
    delayedApprovals.map((d) => ({ id: d.id, open: true }))
  )

  function toggleOpen(isOpen: boolean, id: string) {
    const indexOfId = approvals.findIndex((d) => d.id === id)

    const newApprovals = [
      ...approvals.slice(0, indexOfId),
      { id, open: isOpen },
      ...approvals.slice(indexOfId + 1, approvals.length)
    ]
    setApprovals(newApprovals)
  }

  function isOpen(id: string) {
    let findOpen = approvals.find((d) => d.id === id)
    if (findOpen) {
      return findOpen.open
    }
    return false
  }

  return (
    <div>
      <Headings.H6>{t('Documents with delayed approval')}</Headings.H6>
      <List>
        {delayedApprovals.map((d) => (
          <Item key={d.id}>
            <CommentBox
              title={t(d.id)}
              comment={d.comment}
              isOpen={isOpen(d.id)}
              toggleOpen={(isOpen: boolean) => toggleOpen(isOpen, d.id)}
            />
          </Item>
        ))}
      </List>
    </div>
  )
}

export default withTranslation('inbox')(DelayedApprovalContext)
