import { useRef } from 'react'

import { Modal } from '@flow/modal'
import { PrimaryProgressButton } from '@flow/buttons'
import styled from 'styled-components'
import { useHistory, withRouter } from 'react-router-dom'
import { createStakeholder } from '../../services/stakeholders'
import StakeholderForm from './StakeholderForm'
import axios from 'axios'

const FormGroup = styled.div`
  padding: 20px 0px 0px 0px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse; /*Primarybutton is placed to the right but still triggered on enter key*/
`

const NewUserView = () => {
  const history = useHistory()
  const modalRef = useRef<HTMLDialogElement>(null)
  const errorDiv = useRef<HTMLDivElement>(null)
  return (
    <Modal
      ref={modalRef}
      title="New User"
      onClose={() => {
        history.push('/admin/dealers')
      }}
    >
      <StakeholderForm
        initialValues={{ nid: '', name: '', roles: [] }}
        onSubmit={async (
          values: { nid: string; name: StorageManager; roles: [] },
          actions: {
            resetForm: () => void
            setSubmitting: (arg0: boolean) => void
          }
        ) => {
          try {
            await createStakeholder(values)
            actions.resetForm()
            if (modalRef.current) {
              modalRef.current.close()
            }
          } catch (e) {
            if (axios.isAxiosError(e)) {
              if (errorDiv.current) {
                errorDiv.current.innerText = `Error: ${e.response?.data.error}`
              }
            } else {
              if (errorDiv.current) {
                let message = 'Unknown error'
                errorDiv.current.innerText = `Error: ${message}`
              }
              console.error(e)
            }
            actions.setSubmitting(false)
          }
        }}
      >
        {({ isSubmitting }: { isSubmitting: boolean }) => (
          <FormGroup>
            <ButtonGroup>
              <PrimaryProgressButton type="submit" isLoading={isSubmitting}>
                Create
              </PrimaryProgressButton>
            </ButtonGroup>
          </FormGroup>
        )}
      </StakeholderForm>
      <div ref={errorDiv} />
    </Modal>
  )
}

export default withRouter(NewUserView)
