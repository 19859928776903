import axios from 'axios'

const getFile = async (fileId) => {
  const filePath = `/api/files/${fileId}`

  try {
    const res = await axios({
      method: 'get',
      url: filePath,
      responseType: 'arraybuffer'
    })

    const type = res.headers['content-type']
    if (type.includes('image') || type.includes('pdf')) {
      return { file: res.data, type }
    }

    const error = new Error(
      `Error while getting file. Unexpected content-type: ${type}`
    )
    console.error(error)
    return null
  } catch (error) {
    console.error(error)
    return null
  }
}

const getFileMetadata = async (fileId) => {
  const filePath = `/api/files/${fileId}/meta`

  try {
    const result = await axios.get(filePath)
    return result
  } catch (error) {
    console.error(error)
    return null
  }
}

export default {
  getFile,
  getFileMetadata
}
