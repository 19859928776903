export function FamilyIcon() {
  return (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#a)">
        <g
          clipPath="url(#b)"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#946300"
        >
          <path d="M6.5 2a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM3 3.5a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0ZM17.5 2a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM14 3.5a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0ZM12 14a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm-3 1a3 3 0 1 1 6 0 3 3 0 0 1-6 0ZM8 19h8v5h-2v-3h-4v3H8v-5Z" />
          <path d="m4.802 15.6 5.6 4.2-1.2 1.6-5.6-4.2 1.2-1.6ZM20.402 17.2l-5.6 4.2-1.2-1.6 5.6-4.2 1.2 1.6Z" />
          <path d="M9.087 10.817A4.476 4.476 0 0 0 6.502 10L6.5 9v1A4.5 4.5 0 0 0 2 14.5V21H0v-6.5a6.5 6.5 0 0 1 10.24-5.317l.816.576-1.152 1.634-.817-.576ZM13.763 9.183A6.476 6.476 0 0 1 17.503 8l-.001 1V8h.001a6.5 6.5 0 0 1 6.499 6.5V21h-2v-6.5a4.5 4.5 0 0 0-4.5-4.5V9l-.002 1a4.476 4.476 0 0 0-2.585.817l-.817.576-1.153-1.634.818-.576Z" />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
        <clipPath id="b">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
