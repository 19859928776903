import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { PrimaryButton, PrimaryProgressButton } from '@flow/buttons'
import fileService from '../../../services/files'
import downloadLink from '../../../helpers/downloadLink'
import { ArchieveScreenProps, storedFiles } from '../../../types/archieve'

const Screen = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px;
`

const DocumentList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const DocumentItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 10px;
`

const DocumentName = styled.div`
  padding-right: 15px;
`

const ArchieveFormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 20px;

  /*Hide the real input*/
  input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
`

const ArchieveScreen = ({
  onComplete,
  close,
  t,
  task,
  updateCase
}: ArchieveScreenProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false)

  function onClick(fileId: string, fileName: string) {
    fileService
      .getFile(fileId)
      .then((result) => downloadLink(result?.file, fileName))
  }

  function getDocumentList(fileList: storedFiles) {
    const docList = fileList.map((fileData) => (
      <DocumentItem key={fileData.storeID}>
        <DocumentName>{fileData.filename}</DocumentName>
        <PrimaryButton
          onClick={() => onClick(fileData.storeID, fileData.filename)}
        >
          Download
        </PrimaryButton>
      </DocumentItem>
    ))
    return docList
  }

  function handleComplete() {
    setIsSubmitting(true)
    onComplete(
      task.taskId,
      { archived: true },
      () => {
        updateCase()
        close()
        setIsSubmitting(false)
      },
      () => {
        setIsSubmitting(false)
      }
    )
  }

  return (
    <Screen>
      <ArchieveFormContainer>
        <DocumentList>{getDocumentList(task.context.storedFiles)}</DocumentList>
      </ArchieveFormContainer>
      <ButtonContainer>
        <PrimaryProgressButton
          title={t('Complete')}
          isLoading={isSubmitting}
          onClick={() => handleComplete()}
        >
          {t('Complete')}
        </PrimaryProgressButton>
      </ButtonContainer>
    </Screen>
  )
}

ArchieveScreen.defaultProps = {
  onComplete: () => {},
  close: () => {},
  updateCase: () => {}
}

export default withTranslation('inbox')(ArchieveScreen)
