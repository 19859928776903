import React, { useState } from 'react'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { Formik, FieldArray, ErrorMessage } from 'formik'

import { PrimaryProgressButton, SecondaryButton } from '@flow/buttons'
import { Colors, FontStyles, Headings } from '@flow/style'

import Checkbox from '../../common/Checkbox'
import {
  DocumentOptions,
  SelectDocumentsFormProps,
  TaskData
} from '../../../types/creditCheck'

const FormGroup = styled.div`
  padding: 10px 0px 0px 0px;
`

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse; /*Primarybutton is placed to the right but still triggered on enter key*/
`

const TextArea = styled.textarea`
  width: 100%;
  resize: none;
  border: 1px solid ${Colors.Sea};
  box-sizing: border-box;
  border-radius: 4px;
  ${FontStyles.Normal};
  padding: 10px;
`

const Error = styled.div`
  color: ${Colors.Ferrari};
  padding-bottom: 20px;
`

const CheckboxWrapper = styled.div`
  margin-bottom: 10px;
`

const SelectDocumentsForm = ({
  documentOptions,
  onSave,
  onSubmit,
  t,
  taskdata
}: SelectDocumentsFormProps) => {
  const [isSaving, setIsSaving] = useState(false)

  function handleSave(
    e: React.MouseEvent<Element, MouseEvent>,
    values: TaskData
  ) {
    e.preventDefault()
    setIsSaving(true)
    onSave(values, () => {
      setIsSaving(false)
    })
  }

  const initialValuesSelectedDocuments =
    taskdata.selectedDocuments ||
    documentOptions.filter((d) => d.checked).map((doc) => doc.value)

  return (
    <div>
      <Headings.H6>{t('Document requirements')}</Headings.H6>
      <Formik
        initialValues={{
          selectedDocuments: initialValuesSelectedDocuments || [],
          message: taskdata.message
        }}
        validate={(values: TaskData) => {
          const errors: { message: string } | any = {}
          if (!values.message) {
            console.log('Validation: No message to customer provided')
            errors.message = t('Please provide a message')
          }
          return errors
        }}
        onSubmit={(values: TaskData, { setSubmitting }) => {
          onSubmit(values, () => {
            setSubmitting(false)
          })
        }}
      >
        {({ values, handleSubmit, handleChange, handleBlur, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <FormGroup>
              <FieldArray
                name="selectedDocuments"
                render={(arrayHelpers) => (
                  <div>
                    {documentOptions.map((option: DocumentOptions) => (
                      <CheckboxWrapper key={option.value}>
                        <Checkbox
                          id={option.value}
                          name="selectedDocuments"
                          value={option.value}
                          label={option.label}
                          checked={values.selectedDocuments.includes(
                            option.value
                          )}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            if (e.target.checked) {
                              arrayHelpers.push(option.value)
                            } else {
                              const idx = values.selectedDocuments.indexOf(
                                option.value
                              )
                              arrayHelpers.remove(idx)
                            }
                          }}
                        />
                      </CheckboxWrapper>
                    ))}
                  </div>
                )}
              />
            </FormGroup>
            <FormGroup>
              <TextArea
                name="message"
                rows={2}
                placeholder={t('Message to customer')}
                value={values.message}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <ErrorMessage name="selectedDocuments" component={Error} />
              <ErrorMessage name="message" component={Error} />
            </FormGroup>
            <FormGroup>
              <ButtonGroup>
                <PrimaryProgressButton
                  type="submit"
                  isLoading={isSubmitting || isSaving}
                >
                  {t('Complete')}
                </PrimaryProgressButton>
                <SecondaryButton
                  disabled={isSaving || isSubmitting}
                  onClick={(e: React.MouseEvent) => handleSave(e, values)}
                >
                  {t('Save and close')}
                </SecondaryButton>
              </ButtonGroup>
            </FormGroup>
          </form>
        )}
      </Formik>
    </div>
  )
}

SelectDocumentsForm.defaultProps = {
  taskdata: {},
  onSubmit: () => {},
  onSave: () => {}
}

export default withTranslation('inbox')(SelectDocumentsForm)
