export interface SvgIconProps {
  size?: number
  color?: string
  className?: string
  width?: number
  height?: number
}
export function Chevron({ size = 18, className = '' }: SvgIconProps) {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 10.414 8.5 9l3.5 3.5L15.5 9l1.5 1.414-5 5.086-5-5.086Z"
        fill="currentColor"
      />
    </svg>
  )
}
