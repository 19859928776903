import styled from 'styled-components'

export default styled.div`
  .mainHeader {
    background-color: #000060;
  }

  .mainMenu {
    background-color: #000060;
  }

  .mainMenuIcon.active {
    color: white;
    border-left: 3px solid white;
  }

  .activeElement {
    border-left: 2px solid #000040;
  }
`
