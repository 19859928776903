import React, { useContext } from 'react'
import { TaskProps } from './TaskPropsTypes'

type FlowContextType = TaskProps & {
  isReevaluating?: boolean
  errors?: string[]
}

const FlowContext = React.createContext<FlowContextType | null>(null)

function useFlowContext() {
  return useContext(FlowContext) as FlowContextType
}

export { useFlowContext, FlowContext }
