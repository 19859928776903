type CaseSummaryInfoProps = {
  financialInfo: any
  ncvsID: number
  fixedT: any
}

function CaseSummaryInfo({
  financialInfo,
  ncvsID,
  fixedT
}: CaseSummaryInfoProps) {
  const monthlyCosts = [
    {
      title: 'financial-monthly-cost',
      value: financialInfo.financialMonthlyCost
    },
    {
      title: 'periodic-payment',
      value: financialInfo.periodicPayment,
      suffix: 'kr'
    },
    {
      title: 'calculated-service-agreement-value',
      value: financialInfo.calculatedServiceAgreementValue
    },
    {
      title: 'calculated-vat-added-extra-equipment-value',
      value: financialInfo.calculatedVatAddedExtraEquipmentValue
    },
    {
      title: 'total-leasing-payment',
      value: financialInfo.totalLeasingPayment
    },
    {
      title: 'periodic-payment-two',
      value: financialInfo.periodicPaymentTwo
    }
  ]

  const financialSetup = [
    {
      title: 'number-of-months',
      value: financialInfo.numberOfMonths,
      suffix: fixedT('months')
    },
    {
      title: 'Administration Fee',
      value: financialInfo.administrationFee
    },
    {
      title: 'Term Fee',
      value: financialInfo.termFee
    },
    {
      title: 'periodicity',
      value: financialInfo.periodicity
    },
    {
      title: 'interest-rate-nom',
      value: financialInfo.interestRateNom,
      suffix: '%'
    },
    {
      title: 'interest-rat-eff',
      value: financialInfo.interestRateEff,
      suffix: '%'
    },
    {
      title: 'payment-plan-type',
      value: financialInfo.PaymentPlanType
    }
  ]

  const additionalInfo = [
    {
      title: 'pricelist-name',
      value: financialInfo.pricelistName
    },
    {
      title: 'vat-added-extra-equipment-value',
      value: financialInfo.vatAddedExtraEquipmentValue
    },
    {
      title: 'service-agreement-value',
      value: financialInfo.serviceAgreementValue
    },
    {
      title: 'insurance-comprehensive',
      value: financialInfo.insuranceComprehensive
    },
    {
      title: 'insurance-liability',
      value: financialInfo.insuranceLiability
    },
    {
      title: 'tire-management',
      value: financialInfo.tireManagement,
      suffix: 'kr'
    }
  ]

  const paymentDetails = [
    {
      title: 'purchase-amount-mva',
      value: financialInfo.purchaseAmount,
      suffix: 'kr'
    },
    {
      title: 'import-duty',
      value: financialInfo.importDuty
    },
    {
      title: 'upfront-amount',
      value: financialInfo.upfrontAmount,
      suffix: 'kr'
    },
    {
      title: 'net-loan-amount',
      value: 0,
      suffix: 'kr'
    },
    {
      title: 'registration-fee',
      value: financialInfo.registrationFee,
      suffix: 'kr'
    },
    {
      title: 'establishment-fee',
      value: financialInfo.establishmentFee,
      suffix: 'kr'
    },
    {
      title: 'financed-amount',
      value: financialInfo.financedAmount,
      suffix: 'kr'
    },
    {
      title: 'residual-value-amount',
      value: financialInfo.residualValueAmount
    }
  ]

  if (ncvsID === 545) {
    // only display creditPrice if ncvsID is 545
    paymentDetails[3].value = financialInfo.creditPrice
  }

  return (
    <div className="flex flex-column text-xs h-full gap-4">
      <div className="w-full">
        <p className=" text-gray-800 uppercase font-medium">
          {fixedT('payment-details')}
        </p>
        {paymentDetails.map((paymentDetail, index) => (
          <CaseSummaryDetailsList
            key={index}
            title={fixedT(paymentDetail.title)}
            value={paymentDetail.value}
            suffix={paymentDetail.suffix}
          />
        ))}

        <p className=" text-gray-800 uppercase font-medium pt-2">
          {fixedT('monthly-costs')}
        </p>
        {monthlyCosts.map((monthlyCost, index) => (
          <CaseSummaryDetailsList
            key={index}
            title={fixedT(monthlyCost.title)}
            value={monthlyCost.value}
            suffix={monthlyCost.suffix}
          />
        ))}
      </div>
      <div className="h-full bg-gray-300 w-1" />
      <div className="w-full">
        <p className=" text-gray-800 uppercase font-medium">
          {fixedT('financial-setup')}
        </p>
        {financialSetup.map((setup, index) => (
          <CaseSummaryDetailsList
            key={index}
            title={fixedT(setup.title)}
            value={setup.value}
            suffix={setup.suffix}
          />
        ))}
        <p className=" text-gray-800 uppercase font-medium pt-2">
          {fixedT('additional-info')}
        </p>
        {additionalInfo.map((info, index) => (
          <CaseSummaryDetailsList
            key={index}
            title={fixedT(info.title)}
            value={info.value}
            suffix={info.suffix}
          />
        ))}
      </div>
    </div>
  )
}

type CaseSummaryDetailsListProps = {
  title: string
  value: number | string
  suffix?: string
}

export function CaseSummaryDetailsList({
  title,
  value,
  suffix = 'kr'
}: CaseSummaryDetailsListProps) {
  if (value === null || value === '' || value === 0) {
    return null
  }

  return (
    <data className="text-gray-500 text-xs flex flex-row items-center justify-between">
      <dt>{title}</dt>
      <dd>
        {typeof value === 'number'
          ? `${value.toLocaleString()} ${suffix}`
          : value}
      </dd>
    </data>
  )
}

export default CaseSummaryInfo
