import { useState } from 'react'
import { ThreeOneLayout, Left, Right } from '../layout/ThreeOneLayout'
import { PrimaryButton } from '@flow/buttons'
import axios from 'axios'
import { updateInternalFiles } from '../../services/internalFiles'
import fileService from '../../services/files'
import { useFlowContext } from './useFlowContext'

type UploadedDocumentHandlerProps = {
  file: any
  close: () => void
}

const documentTypes = {
  calculationTemplate: 'Calculation template',
  paycheck: 'Paycheck',
  taxNotice: 'Tax notice',
  employmentContract: 'Employment contract',
  other: 'Other'
}

export const UploadedDocumentHandler = ({
  file,
  close
}: UploadedDocumentHandlerProps) => {
  const [confirmUpload, setConfirmUpload] = useState(false)
  const [documentType, setDocumentType] = useState('unselected')
  const [otherDocumentType, setOtherDocumentType] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { t, flow, onComplete, updateCase, task } = useFlowContext()

  const fileUpload = file[0]

  function handleComplete() {
    setIsSubmitting(true)

    uploadFile()
      .then((res: any) => {
        onComplete(
          task.taskId,
          { fileId: res.id },
          () => {
            updateCase()
          },
          () => {
            setIsSubmitting(false)
          }
        )
      })
      .catch(() => {
        setIsSubmitting(false)
      })
  }

  function uploadFile() {
    const formData = new FormData()
    formData.append('file', fileUpload)

    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }

    return new Promise((resolve, reject) => {
      axios
        .post('/api/files/', formData, config)
        .then((res) => {
          handleAddInternalFile(res.data.id)
          resolve(res.data)
        })
        .catch((err) => {
          console.error(err)
          reject(err)
        })
    })
  }

  async function handleAddInternalFile(fileId: string) {
    const internalFiles = flow.data.internalFiles || []
    const metadata = await fileService.getFileMetadata(fileId)
    internalFiles.push({
      fileId,
      type: documentType === 'other' ? otherDocumentType : documentType,
      ...metadata?.data
    })

    const referenceId = flow.data.referenceId
    await updateInternalFiles(referenceId, internalFiles)
    close()
  }

  return (
    <ThreeOneLayout>
      <Left>
        <div className="h-full w-full">
          {fileUpload?.type === 'application/pdf' ? (
            <embed className="h-full w-full" src={fileUpload?.preview} />
          ) : (
            <div>
              <img className="w-full" src={fileUpload?.preview} alt="preview" />
            </div>
          )}
        </div>
      </Left>
      <Right>
        <div className="flex flex-col justify-end items-end h-full">
          <div className="flex justify-between items-center flex-row w-full">
            <p>{t('document-type')}</p>
            <select
              className="p-1 my-2"
              defaultValue={documentType}
              onChange={(event) =>
                setDocumentType(event.target?.value ?? 'unselected')
              }
            >
              <option disabled value="unselected">
                {t('document-type-select')}
              </option>
              <option value="calculationTemplate">
                {t('calculation-template')}
              </option>
              <option value="paycheck">{t('paycheck')}</option>
              <option value="taxNotice">{t('tax-notice')}</option>
              <option value="employmentContract">
                {t('employment-contract')}
              </option>
              <option value="other">{t('Other')}</option>
            </select>
          </div>
          {documentType === 'other' && (
            <div className="flex justify-between items-center flex-row w-full">
              <p>{t('other-document-type')}</p>
              <input
                type="text"
                className="p-1 my-2"
                placeholder={t('other-document-type')}
                maxLength={15}
                onChange={(event) => {
                  const value = event.target.value
                  if (/^[a-zA-Z0-9 _-]*$/.test(value) || value === '') {
                    setOtherDocumentType(value || 'Other')
                  } else {
                    event.target.value = otherDocumentType
                  }
                }}
              />
            </div>
          )}
          <PrimaryButton
            className="w-full"
            disabled={documentType === 'unselected' || confirmUpload}
            onClick={() => {
              setConfirmUpload(false)
              handleComplete()
            }}
          >
            {documentType !== 'unselected'
              ? t('upload') +
                ' ' +
                t(
                  documentTypes[
                    documentType as keyof typeof documentTypes
                  ].toLowerCase()
                )
              : t('upload')}
          </PrimaryButton>
        </div>
      </Right>
    </ThreeOneLayout>
  )
}
