import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { CenteredSpinner } from '@flow/icons'
import {
  ScreenContainer,
  LeftContent,
  RightContent,
  GroupPadded,
  GroupPaddedGrow
} from '../common/TaskViewContainerStyle'
import ContextGroup from '../common/task-context/ContextGroup'
import AssessmentForm from './AssessmentForm'
import htmlToPdfService from '../../services/html-to-pdf'
import {
  AssessmentScreenProps,
  FormData
} from '../../types/customerDeclaration'

const AssessmentScreen = ({
  close,
  flow,
  onComplete,
  onSave,
  schema,
  t,
  task,
  updateCase,
  user
}: AssessmentScreenProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [pdfDocument, setPdfDocument] = useState<any>(null)

  useEffect(() => {
    setIsLoading(true)
    const { context } = task
    const { customer, coCustomer, contextCustomerDeclarationType } = context

    if (contextCustomerDeclarationType === 'business') {
      htmlToPdfService.getHtmlBusiness(customer).then((result) => {
        if (result) {
          setPdfDocument(result)
          setIsLoading(false)
        } else {
          setPdfDocument(null)
          setIsLoading(false)
        }
      })
    } else {
      htmlToPdfService.getHtmlPrivate(customer, coCustomer).then((result) => {
        if (result) {
          setPdfDocument(result)
          setIsLoading(false)
        } else {
          setPdfDocument(null)
          setIsLoading(false)
        }
      })
    }
  }, [])

  function onFormSubmit(formData: FormData, callback = () => {}) {
    const assessmentData = {
      ...formData,
      name: user.profile.name,
      username: user.profile.username,
      sub: user.profile.sub
    }

    onComplete(
      task.taskId,
      assessmentData,
      () => {
        updateCase()
        close()
      },
      () => {
        callback()
      }
    )
  }

  function onFormSave(formData: FormData, callback = () => {}) {
    onSave(
      task.taskId,
      formData,
      () => {
        updateCase()
        close()
      },
      () => {
        callback()
      }
    )
  }

  const { context } = task
  const { customer, coCustomer } = context

  const riskClassOptions = schema.properties.riskClass.enum.map(
    (a: string, index: number) => ({
      value: a,
      label: t(schema.properties.riskClass.enumNames[index])
    })
  )

  return (
    <ScreenContainer>
      <LeftContent style={{ justifyContent: 'center' }}>
        {isLoading ? (
          <CenteredSpinner />
        ) : (
          <iframe
            title="PDF Document"
            style={{ width: '100%', height: '100%', border: 'none' }}
            srcDoc={pdfDocument}
          />
        )}
      </LeftContent>
      <RightContent>
        <GroupPaddedGrow>
          <ContextGroup
            title={customer.name}
            context={{
              id: customer.id,
              phone: customer.phone,
              email: customer.email
            }}
          />
          {coCustomer && (
            <ContextGroup
              title={coCustomer.name || t('CoCustomer')}
              context={{ id: coCustomer.id }}
            />
          )}
        </GroupPaddedGrow>
        <GroupPadded>
          <AssessmentForm
            riskClassOptions={riskClassOptions}
            taskdata={task.data}
            onSubmit={(formData: FormData, cb: () => void) =>
              onFormSubmit(formData, cb)
            }
            onSave={(formData: FormData, cb: () => void) =>
              onFormSave(formData, cb)
            }
          />
        </GroupPadded>
      </RightContent>
    </ScreenContainer>
  )
}

AssessmentScreen.defaultProps = {
  onComplete: () => {},
  onSave: () => {},
  close: () => {},
  updateCase: () => {},
  user: {},
  flow: null
}

export default withTranslation('inbox')(AssessmentScreen)
