import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Icons } from '@flow/icons'
import { FontStyles, Colors } from '@flow/style'
import { SecondaryButton } from '@flow/buttons'
import { RemovedEsignTaskProps } from '../../types/common/common'

const TaskContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  ${FontStyles.LargeStrong};
`

const TaskDescription = styled.div`
  display: flex;
  align-items: center;
  min-height: 35px;

  p {
    margin: 0;
    padding-left: 20px;
  }
`

const ESignButton = styled(SecondaryButton)`
  color: ${Colors.Ferrari};
  border: 2px solid ${Colors.FerrariLight};
  &:hover {
    border: 2px solid ${Colors.FerrariLight};
  }
`

const RemovedEsignTask = ({ title, actionTitle }: RemovedEsignTaskProps) => {
  return (
    <div>
      <TaskContainer>
        <TaskDescription>
          <Icons.AbortedCircle />
          <p>{title}</p>
        </TaskDescription>
        <ESignButton disabled>{actionTitle}</ESignButton>
      </TaskContainer>
    </div>
  )
}

RemovedEsignTask.propTypes = {
  title: PropTypes.string.isRequired,
  actionTitle: PropTypes.string.isRequired
}

export default RemovedEsignTask
