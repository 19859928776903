import axios from 'axios'

const getHtmlPrivate = async (customer, coCustomer) => {
  const { customerDeclaration } = customer
  const { customerDeclaration: coCustomerDeclaration } = coCustomer || {}

  const people = [
    {
      title: 'Customer',
      ...customerDeclaration
    },
    ...(coCustomerDeclaration
      ? [
          {
            title: 'CoCustomer',
            ...coCustomerDeclaration
          }
        ]
      : [])
  ]

  const customerId = customer.id
  const coCustomerId = coCustomer ? coCustomer.id : ''
  const strPeople = JSON.stringify(people)
  try {
    const query = `customerId=${customerId}&coCustomerId=${coCustomerId}&people=${strPeople}`
    const url = `/api/html-to-pdf/cdp-generate-document-en.html?${encodeURI(
      query
    )}`
    const { data } = await axios.get(url)
    return data
  } catch (error) {
    console.error(error)
    return null
  }
}

const getHtmlBusiness = async (customer) => {
  const { customerDeclaration = {}, submittedBy } = customer

  const companyInformation = JSON.stringify(
    customerDeclaration.companyInformation
  )
  const companyChanges = JSON.stringify(customerDeclaration.companyChanges)
  const pep = JSON.stringify(customerDeclaration.pep)
  const { additionalComment = '' } = customerDeclaration

  const customerId = customer.id
  try {
    const query = `organizationId=${customerId}&companyInformation=${companyInformation}&companyChanges=${companyChanges}&pep=${pep}&additionalComment=${additionalComment}&submittedBy=${submittedBy}`
    const url = `/api/html-to-pdf/cdb-generate-document.html?${encodeURI(
      query
    )}`
    const { data } = await axios.get(url)
    return data
  } catch (error) {
    console.error(error)
    return null
  }
}

const getHtmlBusinessSe = async (customer) => {
  const { customerDeclaration = {}, submittedBy } = customer

  const companyInformation = JSON.stringify(
    customerDeclaration.companyInformation
  )
  const companyChanges = JSON.stringify(customerDeclaration.companyChanges)
  const pep = JSON.stringify(customerDeclaration.pep)
  const { additionalComment = '' } = customerDeclaration

  const customerId = customer.id
  const customerName = customer.name
  try {
    const query = `customerName=${customerName}&organizationId=${customerId}&companyInformation=${companyInformation}&companyChanges=${companyChanges}&pep=${pep}&additionalComment=${additionalComment}&submittedBy=${submittedBy}`
    const url = `/api/html-to-pdf/cdb-generate-document-se.html?${encodeURI(
      query
    )}`
    const { data } = await axios.get(url)
    return data
  } catch (error) {
    console.error(error)
    return null
  }
}

export default {
  getHtmlPrivate,
  getHtmlBusiness,
  getHtmlBusinessSe
}
