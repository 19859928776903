import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'

import ZoomToolbar from './ZoomToolbar'
import { ImageViewerProps } from '../../../types/common/documentsViewer'

const DEFAULT_SCALE = 1

interface StyledImageProps {
  scale: number
}
const StyledImage = styled.img<StyledImageProps>`
  width: 100%;
  display: block;
  transform-origin: top left;
  transform: scale(${(props) => props.scale});
`

const ImageViewer = ({ file, t, type }: ImageViewerProps) => {
  const [scale, setScale] = useState({ scale: DEFAULT_SCALE })

  useEffect(() => {
    resetScale()
  }, [])

  function resetScale() {
    setScale({ scale: DEFAULT_SCALE })
  }

  const imageUrl = window.URL.createObjectURL(new Blob([file], { type }))

  return (
    <div>
      <ZoomToolbar
        scale={scale.scale}
        onZoom={(newScale: number) => {
          setScale({ scale: newScale })
        }}
      />
      <StyledImage
        alt={t('Uploaded document')}
        src={imageUrl}
        scale={scale.scale}
      />
    </div>
  )
}

export default withTranslation('inbox')(ImageViewer)
