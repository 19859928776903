type CaseSummaryStakeholdersProps = {
  customer: any
  coCustomer: any
  vendor: any
  salesPerson: any
  contractNumber: number
  fixedT: any
}

function CaseSummaryStakeholders({
  customer,
  vendor,
  salesPerson,
  coCustomer,
  contractNumber,
  fixedT
}: CaseSummaryStakeholdersProps) {
  const stakeholders = [customer, vendor, salesPerson, coCustomer]
  const definedStakeholders = stakeholders.filter((stakeholder) =>
    Boolean(stakeholder)
  )
  const justifyClass =
    definedStakeholders.length <= 2 ? 'justify-start' : 'justify-between'

  return (
    <div className={`flex flex-row gap-6 text-xs w-full ${justifyClass}`}>
      {customer && (
        <div>
          <p className=" text-gray-800 uppercase font-medium">
            {fixedT('case-summary-customer')}
          </p>
          <p className=" text-gray-500">
            {customer?.name} ({customer?.id})
          </p>
          <p className=" text-gray-500">{customer?.email}</p>
        </div>
      )}
      {coCustomer && (
        <div>
          <p className=" text-gray-800 uppercase font-medium">
            {fixedT('case-summary-coCustomer')}
          </p>
          <p className=" text-gray-500">
            {coCustomer?.name} ({coCustomer?.id})
          </p>
          <p className=" text-gray-500">{coCustomer?.email}</p>
        </div>
      )}
      {salesPerson && (
        <div>
          <p className=" text-gray-800 uppercase font-medium">
            {fixedT('case-summary-sales-person')}
          </p>
          <p className=" text-gray-500">{salesPerson?.name}</p>
          <p className=" text-gray-500">{salesPerson?.email}</p>
        </div>
      )}
      {vendor && (
        <div>
          <p className=" text-gray-800 uppercase font-medium">
            {fixedT('case-summary-vendor')}
          </p>
          <p className=" text-gray-500">{vendor?.name}</p>
          <p className=" text-gray-500">{vendor?.email}</p>
        </div>
      )}
      {contractNumber && (
        <div>
          <p className=" text-gray-800 uppercase font-medium">
            {fixedT('contract-number')}
          </p>
          <p className="text-gray-500">{contractNumber}</p>
        </div>
      )}
    </div>
  )
}

export default CaseSummaryStakeholders
