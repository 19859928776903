import { HandMoneyIcon } from '../../icons/HandMoneyIcon'
import { formatMoney } from '../../utils/formatMoney'
import AffordabilityAnswers from '../../types/AffordabilityAnswers'

export type IncomeCardProps = {
  affordabilityAnswers: AffordabilityAnswers
}

export function IncomeCard({ affordabilityAnswers }: IncomeCardProps) {
  const totalIncome = calculateTotalIncome(affordabilityAnswers)
  return (
    <div className="row-span-2">
      <div className="rounded-lg mb-6 border border-gray-300 overflow-hidden">
        <div className="bg-grass-90 text-grass-20 p-4 border-b border-gray-300 flex gap-3">
          <HandMoneyIcon size={24} />
          <h2 className="text-md font-medium">Inntekt</h2>
        </div>
        <div className="text-sm divide-y divide-gray-200 p-4">
          <div className="py-3 flex justify-between">
            <span className="pr-3">Skattefrie inntekter</span>
            <span>{formatMoney(affordabilityAnswers['taxFreeIncome'])}</span>
          </div>
          <div className="py-3 flex justify-between">
            <span className="pr-3">
              Utbytte eller inntekt fra næringsvirksomhet
            </span>
            <span>{formatMoney(affordabilityAnswers['dividendAmount'])}</span>
          </div>
          <div className="py-3 flex justify-between">
            <span className="pr-3">Har partner/samboer</span>
            <span>
              {affordabilityAnswers['hasCoBorrower'] === 'yes' ? 'Ja' : 'Nei'}
            </span>
          </div>
          {affordabilityAnswers['hasCoBorrower'] === 'yes' && (
            <div className="py-3 flex justify-between">
              <span className="pr-3">Partners brutto årsinntekt</span>
              <span>
                {formatMoney(affordabilityAnswers['coBorrowerIncome'])}
              </span>
            </div>
          )}
          <div className="py-3 flex justify-between font-medium">
            <span className="pr-3">Sum inntekter</span>
            <span>{formatMoney(totalIncome)}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

function calculateTotalIncome(affordabilityAnswers: any) {
  let totalIncome = 0

  if (affordabilityAnswers) {
    if (affordabilityAnswers['taxFreeIncome']) {
      totalIncome += affordabilityAnswers['taxFreeIncome']
    }
    if (affordabilityAnswers['dividendAmount']) {
      totalIncome += affordabilityAnswers['dividendAmount']
    }
    if (affordabilityAnswers['coBorrowerIncome']) {
      totalIncome += affordabilityAnswers['coBorrowerIncome']
    }
  }

  return totalIncome
}
