import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { CenteredSpinner } from '@flow/icons'
import fileService from '../../../services/files'

import {
  ScreenContainer,
  LeftContent,
  RightContent,
  Group,
  GroupPadded,
  GroupPaddedGrow
} from '../TaskViewContainerStyle'
import ApprovalForm from './ApprovalForm'
import PdfViewer from '../documentsViewer/PdfViewer'
import ImageViewer from '../documentsViewer/ImageViewer'
import Context from '../task-context/Context'
import CommentBox from '../CommentBox'
import { ApprovalScreenProps, FormValues } from '../../../types/common/approval'

const ApproveScreen = ({
  task,
  flow,
  close,
  onComplete,
  onSave,
  schema,
  t,
  updateCase,
  user
}: ApprovalScreenProps) => {
  const [isImageFile, setIsImageFile] = useState(false)
  const [isPdfFile, setIsPdfFile] = useState(false)
  const [file, setFile] = useState<any>(null)
  const [fileType, setFileType] = useState('')
  const [fileError, setFileError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isAuditCommentOpen, setIsAuditCommentOpen] = useState(true)

  useEffect(() => {
    const context = task.context
    setIsLoading(true)
    fileService.getFile(context.fileId).then((result) => {
      if (!result) {
        setFileError('Could not find file')
        setIsLoading(false)
        return
      }
      const { file, type } = result
      if (type.includes('image')) {
        setFileType(type)
        setFile(file)
        setIsImageFile(true)
      } else if (type.includes('pdf')) {
        setFileType(type)
        setFile(file)
        setIsPdfFile(true)
      } else {
        setFileError(t('FormatNotSupported', { type }))
      }
      setIsLoading(false)
    })
  }, [])

  function onFormSubmit(formData: FormValues, callback = () => {}) {
    const approveData = {
      ...formData,
      name: user.profile.name,
      username: user.profile.username,
      sub: user.profile.sub
    }

    onComplete(
      task.taskId,
      approveData,
      () => {
        updateCase()
        close()
      },
      () => {
        callback()
      }
    )
  }

  function onFormSave(formData: FormValues, callback = () => {}) {
    onSave(
      task.taskId,
      formData,
      () => {
        updateCase()
        close()
      },
      () => {
        callback()
      }
    )
  }

  const context = task.context

  const approvalSchema = schema.properties.approval
  const approvalOptions = approvalSchema.enum.map((a, index) => ({
    value: a,
    label: t(approvalSchema.enumNames[index])
  }))

  return (
    <ScreenContainer>
      <LeftContent>
        {isLoading && <CenteredSpinner />}
        {fileError && <p>{t(fileError)}</p>}
        {isImageFile && <ImageViewer type={fileType} file={file} />}
        {isPdfFile && <PdfViewer file={file} />}
      </LeftContent>
      <RightContent>
        {context.auditComment && (
          <Group>
            <CommentBox
              title="Comment from audit"
              comment={context.auditComment}
              isOpen={isAuditCommentOpen}
              toggleOpen={(isOpen: boolean) => setIsAuditCommentOpen(isOpen)}
            />
          </Group>
        )}
        <GroupPaddedGrow>
          <Context context={context} />
        </GroupPaddedGrow>
        <GroupPadded>
          <ApprovalForm
            approvalOptions={approvalOptions}
            taskdata={task.data}
            onSubmit={(formData: FormValues, cb: () => void) =>
              onFormSubmit(formData, cb)
            }
            onSave={(formData: FormValues, cb: () => void) =>
              onFormSave(formData, cb)
            }
          />
        </GroupPadded>
      </RightContent>
    </ScreenContainer>
  )
}

ApproveScreen.defaultProps = {
  onComplete: () => {},
  onSave: () => {},
  close: () => {},
  updateCase: () => {},
  user: {}
}

export default withTranslation('inbox')(ApproveScreen)
