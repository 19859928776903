import { useState } from 'react'
import { ProgressCircle } from '../../icons/ProgressCircle'

type ProgressBarItemProps = {
  status?:
    | 'completed'
    | 'in-progress'
    | 'not-started'
    | 'rejected'
    | 'trial'
    | 'finalized'
  label: string
  hoverChild?: any
}

function ProgressBarItem({ status, label, hoverChild }: ProgressBarItemProps) {
  const [hover, setHover] = useState(false)

  return (
    <div
      onMouseLeave={() => {
        setHover(false)
      }}
      onMouseEnter={() => {
        setHover(true)
      }}
      className="relative"
    >
      {hover && hoverChild && (
        <div className=" w-32 absolute left-1/2 transform -translate-x-1/2 top-full rounded border border-solid shadow-md z-10 bg-white p-2">
          {hoverChild}
        </div>
      )}
      {status === 'completed' ? (
        <div className="w-8 h-8 rounded-full border-4 border-solid flex items-center justify-center bg-blue-500 relative">
          <div className="w-4 h-4 flex items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="lucide lucide-check"
            >
              <polyline points="20 6 9 17 4 12"></polyline>
            </svg>
          </div>
        </div>
      ) : status === 'rejected' ? (
        <div className="w-8 h-8 rounded-full border-4 border-solid flex items-center justify-center bg-red-500 relative">
          <div className="w-4 h-4 flex items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="lucide lucide-check"
            >
              <polyline points="4 14 20 14"></polyline>
            </svg>
          </div>
        </div>
      ) : status === 'trial' ? (
        <div className="w-8 h-8 rounded-full border-4 border-solid flex items-center justify-center bg-yellow-50 relative">
          <div className="w-4 h-4 flex items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="lucide lucide-check"
            ></svg>
          </div>
        </div>
      ) : status === 'finalized' ? (
        <div className="w-8 h-8 rounded-full border-4 border-solid flex items-center justify-center bg-green-500 relative">
          <div className="w-4 h-4 flex items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="lucide lucide-check"
            >
              <polyline points="20 6 9 17 4 12"></polyline>
            </svg>
          </div>
        </div>
      ) : (
        <div className="w-8 h-8 rounded-full border-4 border-solid flex items-center justify-center relative">
          <div className="text-xs">
            <div className="relative">
              <ProgressCircle status={status} />
            </div>
          </div>
        </div>
      )}
      <div></div>
      <div
        className={`absolute left-1/2 transform -translate-x-1/2 top-full text-xs text-center ${
          status === 'in-progress'
            ? 'text-gray-900 font-medium'
            : 'text-gray-700'
        }`}
      >
        <p className="pt-1" style={{ userSelect: 'none' }}>
          {label}
        </p>
      </div>
    </div>
  )
}

export default ProgressBarItem
