export function formatMoney(
  value?: number | string,
  prefix = false,
  decimals = 0
) {
  const numberValue = Number(value)
  const formatedNumber = new Intl.NumberFormat('no-NB', {
    style: prefix ? 'currency' : 'decimal',
    currency: 'NOK',
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  }).format(isNaN(numberValue) ? 0 : numberValue)
  return formatedNumber
}
