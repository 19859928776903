const businessStatus = [
  { label: 'business-status-assessment', value: 'assessment' },
  {
    label: 'business-status-awaiting-customer-answer',
    value: 'awaiting-customer-answer'
  },
  { label: 'business-status-rejected-audit', value: 'rejected-audit' },
  { label: 'business-status-archiving', value: 'archiving' },
  { label: 'business-status-audit', value: 'audit' },
  { label: 'business-status-post-control', value: 'post-control' },
  {
    label: 'business-status-collecting-document-requirements',
    value: 'collecting-document-requirements'
  },
  {
    label: 'business-status-send-completion-message',
    value: 'send-message-to-salesperson'
  },
  {
    label: 'business-status-update-ncvs-data',
    value: 'updating-with-new-data-from-ncvs'
  },
  { label: 'business-status-start-payout', value: 'start-payout' }
]

export default businessStatus
