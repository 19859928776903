import { Route } from 'react-router-dom'

import DealersScreen from './DealersScreen'

const App = () => (
  <Route path="/admin/dealers">
    <DealersScreen />
  </Route>
)

export default App
