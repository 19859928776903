import { PalaceIcon } from '../../icons/PalaceIcon'
import { formatMoney } from '../../utils/formatMoney'
import AffordabilityAnswers from '../../types/AffordabilityAnswers'

export type LivingSituationCardProps = {
  affordabilityAnswers: AffordabilityAnswers
}

export function LivingSituationCard({
  affordabilityAnswers
}: LivingSituationCardProps) {
  return (
    <div className="row-span-1">
      <div className="rounded-lg mb-6 border border-gray-300 overflow-hidden">
        <div className="bg-primary-50 text-primary-800  p-4 border-b border-gray-300 flex gap-3">
          <PalaceIcon />
          <h2 className="text-md font-medium">Bosituasjon</h2>
        </div>
        <div className="text-sm divide-y divide-gray-200 p-4">
          <div className="py-3 flex justify-between">
            <span className="pr-3">Bosituasjon</span>
            <span>
              {affordabilityAnswers['livingSituation'] ?? 'Ikke valgt'}
            </span>
          </div>
          {affordabilityAnswers['livingSituation'] !== 'Eier' && (
            <div className="py-3 flex justify-between">
              <span className="pr-3">Leiekostnad</span>
              <span>{formatMoney(affordabilityAnswers['rentCost'])}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
