import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Headings, FontStyles } from '@flow/style'
import { NameBaseContextGroupProps } from '../../../types/common/task-context'

const ContextGroupContainer = styled.div`
  padding: 0 0 10px 0;
`

const Title = styled(Headings.H6)`
  :first-letter {
    text-transform: capitalize;
  }
`

const MainContent = styled.p`
  ${FontStyles.LargeStrong};
`

const NameBasedContextGroup = ({
  title,
  context
}: NameBaseContextGroupProps) => (
  <ContextGroupContainer>
    <Title>{title}</Title>
    {Object.keys(context)
      .sort((key) => (key === 'name' ? -1 : 1))
      .map((key) => {
        const value = context[key]
        if (key === 'name') {
          return (
            <div key={key}>
              <MainContent>{value}</MainContent>
            </div>
          )
        }
        return <div key={key}>{value}</div>
      })}
  </ContextGroupContainer>
)

NameBasedContextGroup.propTypes = {
  title: PropTypes.string,
  context: PropTypes.shape({}).isRequired
}

NameBasedContextGroup.defaultProps = {
  title: ''
}

export default NameBasedContextGroup
