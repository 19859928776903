const documentGroupBy: { [key: string]: string } = {
  BOND: 'customer-task',
  PAYCHECK_1: 'customer-task',
  PAYCHECK_2: 'customer-task',
  PAYCHECK_3: 'customer-task',
  LIEN_OF_MOTORVEHICLE: 'customer-task',
  GUARANTOR_DOCUMENTS: 'customer-task',
  GUARANTOR: 'customer-task',
  CO_GUARANTOR: 'customer-task',
  CUSTOMER_DECLARATION: 'customer-task',
  CUSTOMER_DECLARATION_SE: 'customer-task',
  CO_CUSTOMER_DECLARATION: 'customer-task',
  CO_CUSTOMER_DECLARATION_SE: 'customer-task',
  CO_GUARANTOR_DOCUMENTS: 'customer-task',
  COCUSTOMER_ID_CONTROL_DOCUMENT: 'customer-task',
  CUSTOMER_ID_CONTROL_DOCUMENT: 'customer-task',
  GUARANTOR_ID_CONTROL_DOCUMENT: 'customer-task',
  COGUARANTOR_ID_CONTROL_DOCUMENT: 'customer-task',
  GUARANTOR_ID_COPY: 'customer-task',
  COGUARANTOR_ID_COPY: 'customer-task',
  PURCHASE_CONTRACT: 'dealer-task',
  VEHICLE_REGISTRATION_PAPERS: 'caseworker-task',
  VEHICLE_REGISTERED_OKAY: 'dealer-task',
  PAYMENTPLAN: 'caseworker-task',
  CREDITCASE: 'caseworker-task',
  SOURCE_OF_FUNDS: 'customer-task',
  CERTIFICATE_OF_INCORPORATION: 'caseworker-task',
  GUARANTOR_CERTIFICATE_OF_INCORPORATION: 'caseworker-task',
  CO_GUARANTOR_CERTIFICATE_OF_INCORPORATION: 'caseworker-task',
  REPURCHASE_AGREEMENT: 'dealer-task',
  BUY_BACK_GUARANTEE: 'customer-task',
  CUSTOMER_ID_COPY: 'customer-task',
  COCUSTOMER_ID_COPY: 'customer-task',
  CUSTOMER_PARTNER_PRINT_CONTRACT: 'customer-task',
  INVOICE: 'caseworker-task',
  INVOICE_SE: 'caseworker-task',
  LEASING_PRIVATE_CONTRACT: 'customer-task',
  LEASING_AGREEMENT: 'customer-task',
  SERVICE_AGREEMENT: 'customer-task',
  LEASING_AND_SERVICE_AGREEMENT_CUSTOMER: 'customer-task',
  REPURCHASE_AND_SERVICE_AGREEMENT_VENDOR: 'dealer-task',
  JUDICIAL_REGISTRATION: 'customer-task',
  LEASING_AND_SERVICE_AGREEMENT: 'customer-task',
  PARTNER_CONTRACT: 'customer-task',
  CUSTOMER_CONTRACT: 'customer-task',
  'AD-HOC1': 'optional-task',
  'AD-HOC2': 'optional-task',
  'AD-HOC3': 'optional-task',
  AD_HOC1: 'optional-task',
  AD_HOC2: 'optional-task',
  AD_HOC3: 'optional-task'
}

export default documentGroupBy
