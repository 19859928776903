import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import axios from 'axios'
import { SecondaryButton, PrimaryProgressButton } from '@flow/buttons'
import { FontStyles, Colors } from '@flow/style'
import { Icons } from '@flow/icons'
import { UploadScreenProps } from '../../types/common/common'

interface ScreenProps {
  dragging: number
}

const Screen = styled.div<ScreenProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  border: ${({ dragging }) => (dragging ? 'dashed 4px' : 'none')};
  border-color: ${({ dragging }) => (dragging ? Colors.Sea : 'none')};
  box-sizing: border-box;
  background-color: ${({ dragging }) =>
    dragging ? 'rgba(255,255,255,.8)' : 'none'};
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px;
`

const UploadButton = styled(SecondaryButton)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 130px;

  svg {
    padding-right: 10px;
  }
`

const UploadFormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 20px;

  /*Hide the real input*/
  input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
`

const FileTitle = styled.div`
  padding: 20px;
  ${FontStyles.Large};
  display: flex;
`

const RemoveButton = styled.button`
  outline: none;
  border: none;
  cursor: pointer;
  background-color: ${Colors.Snow};
`

const UploadScreen = ({
  close,
  onComplete,
  t,
  task,
  updateCase
}: UploadScreenProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [dragging, setDragging] = useState(0)
  const [file, setFile] = useState<File | string>('')
  let fileInput = useRef<HTMLInputElement | null>(null)

  function uploadFile() {
    const formData = new FormData()
    formData.append('file', file)

    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }

    return new Promise((resolve, reject) => {
      axios
        .post('/api/files/', formData, config)
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          console.error(err)
          reject(err)
        })
    })
  }

  function handleComplete() {
    setIsSubmitting(true)

    uploadFile()
      .then((res: any) => {
        onComplete(
          task.taskId,
          { fileId: res.id },
          () => {
            updateCase()
            close()
          },
          () => {
            setIsSubmitting(false)
          }
        )
      })
      .catch(() => {
        setIsSubmitting(false)
      })
  }

  function handleFileSelectionClick(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault()
    fileInput.current?.click()
  }

  function handleRemoveClick(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault()
    setFile('')
  }

  function handlePasteEvent(e: React.ClipboardEvent<HTMLDivElement>) {
    const { items } = e.clipboardData

    let blob = null
    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf('image') === 0) {
        blob = items[i].getAsFile()
      }
    }

    if (blob) {
      setFile(blob)
    }
  }

  function handleFileDrop(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault()
    e.stopPropagation()

    const file = e.dataTransfer.files[0]
    console.log(file)
    setFile(file)
    setDragging(0)
  }

  function handleDragOver(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault()
    e.stopPropagation()
  }

  function handleDragEnter() {
    setDragging(dragging + 1)
  }

  function handleDragLeave() {
    setDragging(dragging - 1)
  }

  return (
    <Screen
      onPaste={(e) => handlePasteEvent(e)}
      onDrop={(e) => handleFileDrop(e)}
      onDragOver={(e) => handleDragOver(e)}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      dragging={dragging}
    >
      <UploadFormContainer>
        {!file && !isSubmitting && !dragging && (
          <form>
            <input
              type="file"
              name="file"
              id="file"
              ref={(input) => {
                fileInput.current = input
              }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.files) {
                  setFile(e.target.files[0])
                }
              }}
            />
            <UploadButton
              size="large"
              onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                handleFileSelectionClick(e)
              }
            >
              <Icons.Upload color={Colors.Sea} />
              {t('Select a file')}
            </UploadButton>
          </form>
        )}
        {!!dragging && (
          <p style={{ fontSize: '22px' }}>{t('Drop file here to upload it')}</p>
        )}
        {file && !dragging && (
          <FileTitle>
            {file instanceof File && <p>{file.name}</p>}

            {/* <p>{file.name}</p> */}
            <RemoveButton
              disabled={isSubmitting}
              type="button"
              title={t('Remove file')}
              onClick={(e) => handleRemoveClick(e)}
            >
              <Icons.Remove color={Colors.Ferrari} />
            </RemoveButton>
          </FileTitle>
        )}
      </UploadFormContainer>
      <ButtonContainer>
        <PrimaryProgressButton
          type="submit"
          disabled={!file}
          isLoading={isSubmitting}
          onClick={() => handleComplete()}
        >
          {t('Complete')}
        </PrimaryProgressButton>
      </ButtonContainer>
    </Screen>
  )
}

UploadScreen.defaultProps = {
  onComplete: () => {},
  close: () => {},
  updateCase: () => {}
}

export default withTranslation('inbox')(UploadScreen)
