const userTaskGroupBy: { [key: string]: string } = {
  'cc-approve-document': 'caseworker-task',
  'cc-select-document-requirements': 'caseworker-task',
  'payout-control': 'caseworker-task',
  'payout-done': 'caseworker-task',
  'cdp-assessment': 'caseworker-task',
  'audit-documents': 'caseworker-task',
  'select-post-control': 'caseworker-task',
  'post-control': 'caseworker-task',
  'archive-document': 'caseworker-task',
  'approve-signed-document': 'caseworker-task',
  'dc-assess-customer-declaration': 'caseworker-task',
  'cdp-provide-information-with-co-applicant': 'customer-task',
  'cdp-provide-information': 'customer-task',
  'sofp-provide-information': 'customer-task',
  'cc-upload-document': 'customer-task',
  'affordability-provide-information': 'customer-task',
  'cdp-se-provide-information': 'customer-task'
}

export default userTaskGroupBy
