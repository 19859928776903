import React from 'react'
import styled from 'styled-components'

const MainLogo = () => (
  <MainLogoWrapper>
    <div>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="154.2274048px"
        height="68.03149416px"
        viewBox="0 0 192.784256 85.0393677"
        style={{ enableBackground: 'new 0 0 192.784256 85.0393677' }}
        xmlSpace="preserve"
      >
        <style type="text/css">
          {'.st0 {fill:#FFFFFF}'}
          {'.st1 {fill:none}'}
        </style>
        <g id="Lager_1">
          <g>
            <path
              className="st0"
              d="M152.3417816,34.3858223c-5.5369415,0.4284973-9.9114838,4.9093437-10.2570953,10.4017677
  c-0.4083252,6.4888878,4.7336578,11.8647041,11.1344604,11.8647041c2.3126068,0,5.4428101-1.1502838,6.8495941-3.5784264
  v3.2798653h4.3174133l0.009201-10.6365547C164.3953552,38.7908287,158.7553711,33.8894806,152.3417816,34.3858223z
   M159.6370239,45.8424683c-0.1869354,3.6602325-3.4359283,6.5116501-7.235611,6.0428581
  c-2.9055939-0.3584633-5.2416077-2.7250023-5.567215-5.6344528c-0.4343414-3.8811798,2.5914307-7.1680946,6.3849487-7.1680946
  c3.437439,0,6.2444763,2.6989479,6.4178772,6.0933685V45.8424683z"
            />
            <path
              className="st0"
              d="M128.7489014,34.3552208c-5.8636932,0.1645622-10.9038696,4.7301826-10.9038696,11.2700157
  c0,6.0278511,4.8805618,11.0673141,11.1629944,11.0676765c4.3799438,0.0002518,8.6602173-2.6403809,10.3535767-6.9246445
  l-4.2981567-1.2820473c-0.7774048,1.7577896-2.6550293,3.2936249-4.8235474,3.6977196
  c-3.0191956,0.5626259-5.7990646-1.2840805-6.8898468-3.3413696l16.7210236-4.5564117
  C139.5773315,39.9916077,135.8101654,34.1570473,128.7489014,34.3552208z M122.496254,45.049881
  c0-2.3321037,1.3606567-5.0104141,4.382019-6.1445961c3.4303055-1.2876968,6.4272079,0.4209099,7.6894608,2.8275146
  L122.496254,45.049881z"
            />
            <g>
              <g>
                <path
                  className="st0"
                  d="M91.9575424,38.8875618l0.0000153-4.5811462c-4.1689072,0-5.6459122,2.0543823-6.271492,3.1912956
      v-2.4594994l-4.7541275,0.000103v10.2196045v11.0958328l4.8816071,0.0000038
      c-0.0040817-6.59095-0.0141525-6.482151-0.0141525-10.4580421
      C85.7993927,40.9707718,88.6458435,39.0544548,91.9575424,38.8875618z"
                />
                <polygon
                  className="st0"
                  points="47.901329,47.7897224 33.3222961,30.619833 28.3450394,30.619833 28.3450394,56.3183327
      33.4904327,56.3183327 33.4904327,39.131916 48.2524757,56.3537521 52.8147659,56.3537521 52.8171616,30.619833
      47.901329,30.619833"
                />
                <path
                  className="st0"
                  d="M114.8519669,28.3464565h-4.5963974v8.3812504
      c-1.0920105-1.4351273-4.5016785-2.5793114-7.4353561-2.3418732c-5.4853287,0.4439583-9.9114838,4.9093475-10.257103,10.4017715
      c-0.4083099,6.4888878,4.7336655,11.8788795,11.1344681,11.8788795c2.3891373,0,5.7148361-1.1435814,6.8495865-3.1624908
      l-0.0049133,2.8497581h4.3097153V45.7171936c0.0012665-0.0691338,0.0025864-0.1382675,0.0025864-0.2078705
      c0-0.0695076-0.0013199-0.1386414-0.0025864-0.2080574V28.3464565z M110.1154556,45.8424835
      c-0.1869431,3.6602364-3.4359283,6.5116501-7.2356033,6.0428581c-2.9056015-0.3584595-5.2416153-2.7250023-5.5672226-5.6344528
      c-0.4343491-3.8811798,2.5914307-7.1680946,6.3849487-7.1680946c3.4374313,0,6.2444763,2.6989479,6.4178772,6.0933685
      V45.8424835z"
                />
                <path
                  className="st0"
                  d="M66.8697815,34.3390427c-6.1691971,0-11.1703033,5.0011063-11.1703033,11.1703072
      c0,6.1691742,5.0011063,11.1703072,11.1703033,11.1703072c6.1692047,0,11.170311-5.001133,11.170311-11.1703072
      C78.0400925,39.3401489,73.0389862,34.3390427,66.8697815,34.3390427z M66.856163,51.931076
      c-3.5466156,0-6.4217453-2.8751068-6.4217453-6.4217262c0-3.5466461,2.8751297-6.4217491,6.4217453-6.4217491
      c3.5466232,0,6.4217529,2.875103,6.4217529,6.4217491C73.277916,49.0559692,70.4027863,51.931076,66.856163,51.931076z"
                />
              </g>
            </g>
            <line
              className="st0"
              x1="122.5390854"
              y1="45.1165085"
              x2="122.5390854"
              y2="45.1165085"
            />
            <line
              className="st0"
              x1="134.6718292"
              y1="41.7110023"
              x2="134.6718292"
              y2="41.7110062"
            />
          </g>
        </g>
        <g id="Lager_3">
          <rect className="st1" width="192.784256" height="85.0393677" />
        </g>
      </svg>
    </div>
  </MainLogoWrapper>
)

export default MainLogo

export const MainLogoWrapper = styled.div`
  padding-left: 32px;

  .title {
    font-family: Montserrat Alternates;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    font-size: 24px;
    text-align: right;

    color: #2b2b2b;
    padding-right: 5px;
  }

  .cloud {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    font-size: 24px;
    text-align: right;

    color: #d44235;
  }
`
